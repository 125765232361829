import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import StepCount from "./StepCount";
import WalkingTime from "./WalkingTime";
import Stack from "@mui/material/Stack";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import StrideLength from "./StrideLength";
import KneeRangeMotion from "./KneeRangeMotion";
import MetricsSelector from "./MetricsSelector";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import DailyPicker from "./DailyPicker";
import { useDashboardContext } from "../../contexts/DashboardContext";
import { fetchDailyDataByPatientIdAndWeek } from "../../utils/dataFetcher";
import { fetchDailyDataByPatientIdAndMonth } from "../../utils/dataFetcher";
import { fetchDailyDataByPatientIdAndYear } from "../../utils/dataFetcher";
import { fetchDailyDataByPatientIdAndCustomRange } from "../../utils/dataFetcher";
import { getDailyDataByRange, getDailyDataByMonth, getDailyDataByWeek, getDailyDataByYear } from "../../utils/fastAPI"
import { calculateAverageMetrics } from "../../utils/dataFetcher";
import { getWeekStartEnd } from "../../utils/timeUtils";
import OneTapButton from "./OneTapButton";
import { Button, Modal } from "@mui/material";
import { Modal as BaseModal } from '@mui/base/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { formatDateString } from "../../utils/timeUtils";
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDocument from './PDFDocument';
import { useRef } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const options = [
  'Date of device supply',
  'Minutes educating patient',
  'Via',
];

const DailyData = () => {
  const [selectedMetrics, setSelectedMetrics] = useState(["Step Count", "Walking Time", "Stride Length", "Knee Range Motion"]);
  const [view, setView] = useState("week");
  const [week, setWeek] = useState(new Date());
  const [weekStart, setWeekStart] = useState(getWeekStartEnd(new Date()).firstDay);
  const [weekEnd, setWeekEnd] = useState(getWeekStartEnd(new Date()).lastDay);
  const [month, setMonth] = useState(new Date());
  const [year, setYear] = useState(new Date());
  const [dateStart, setDateStart] = useState(getWeekStartEnd(new Date()).firstDay);
  const [dateEnd, setDateEnd] = useState(getWeekStartEnd(new Date()).lastDay);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [averageData, setAverageData] = useState({
    averageStepCount: 0,
    averageWalkingTime: 0,
    averageStrideLength: 0,
    averageKneeRangeMotion: 0,
  });
  const [openModal, setOpenModal] = useState(false);
  const { patientId, patientName, patientInfo } = useDashboardContext();
  const [chartsReady, setChartsReady] = useState({
    stepCountReady: false,
    walkingTimeReady: false,
    strideLengthReady: false,
    kneeRangeMotionReady: false,
  });
  const [pdfCharts, setPdfCharts] = useState([]);

  const stepCountRef = useRef();
  const walkingTimeRef = useRef();
  const strideLengthRef = useRef();
  const kneeRangeMotionRef = useRef();

  const onWalkingTimeChartRendered = () => {
    setChartsReady(prev => ({ ...prev, walkingTimeReady: true }));
  };

  const onStrideLengthChartRendered = () => {
    setChartsReady(prev => ({ ...prev, strideLengthReady: true }));
  };

  const onKneeRangeMotionChartRendered = () => {
    setChartsReady(prev => ({ ...prev, kneeRangeMotionReady: true }));
  };


  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  const generateDefaultReportName = (patientName) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `Report_${patientName}_${year}_${month}_${day}_daily_data`;
  }


  const [reportName, setReportName] = useState(() => {
    return generateDefaultReportName(patientName);
  });

  const handleReportNameInputChange = (event) => {
    setReportName(event.target.value);
  };

  const [dateOfDeviceSupply, setDateOfDeviceSupply] = useState("");

  const handleDateOfDeviceSupplyChange = (event) => {
    setDateOfDeviceSupply(event.target.value);
  }

  const [minutesEducatingPatient, setMinutesEducatingPatient] = useState("");

  const hanldeMinutesEducatingPatientChange = (event) => {
    setMinutesEducatingPatient(event.target.value);
  }

  const [via, setVia] = useState("");

  const handleViaChange = (event) => {
    setVia(event.target.value);
  }

  const [shortNotes, setShortNotes] = useState("");

  const handleShortNotesChange = (event) => {
    setShortNotes(event.target.value);
  }

  const [clinicianName, setClinicianName] = useState("");

  const handleClinicianNameChange = (event) => {
    setClinicianName(event.target.value);
  }

  const [avgMetrics, setAvgMetrics] = useState({});

  useEffect(() => {
    setAvgMetrics(calculateAverageMetrics(data, view));
  }, [data, view]);

  const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      xs: '50%',
      sm: '60%',
      md: '70%',
      lg: '80%',
    },
    bgcolor: 'background.paper',
    border: '1px solid #F5F5F5',
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };


  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    const fetchData = async () => {
      try {
        let fetchedData;
        switch (view) {
          case "month":
            fetchedData = await getDailyDataByMonth(patientId, month);
            break;
          case "year":
            fetchedData = await getDailyDataByYear(patientId, year);
            break;
          case "custom":
            fetchedData = await getDailyDataByRange(patientId, dateStart, dateEnd);
            break;
          default: // Default to weekly view
            fetchedData = await getDailyDataByWeek(patientId, weekStart, weekEnd);
        }

        if (isMounted) {
          // console.log(`${view} Data:`, fetchedData);
          setData(fetchedData);
          setAverageData(calculateAverageMetrics(fetchedData, view));
          console.log("Average Data:", calculateAverageMetrics(fetchedData, view));
          console.log("Data:", fetchedData);
          // if (view === "year") {
          //   console.log(fetchedData);
          //   setAverageDataForYear(calculateOverallAverageForYear(fetchedData));
          // }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle the error, e.g., by setting an error message in state,
        // or checking if the error is due to permission issues
        if (error.message.includes("Missing or insufficient permissions")) {
          // Handle permission-related errors, e.g., reset state or redirect
          // Optionally, you might want to check if the user is logged out here
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    }
  }, [patientId, view, weekStart, weekEnd, month, year, dateStart, dateEnd]);

  useEffect(() => console.log(data))

  const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    color: '#5a5a5a',
    display: 'inline-block',
    marginRight: theme.spacing(2),
  }));



  return (
    <Box display="flex" flexDirection="column" m={1}>
      {/* Toggle chart type */}
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <ToggleButtonGroup
          size="large"
          aria-label="Large Size"
          variant="text"
          value={view}
          exclusive
        >
          <ToggleButton value="week" onClick={() => setView("week")}>Week</ToggleButton>
          <ToggleButton value="month" onClick={() => setView("month")}>Month</ToggleButton>
          <ToggleButton value="year" onClick={() => setView("year")}>Year</ToggleButton>
          <ToggleButton value="custom" onClick={() => setView("custom")} >Custom</ToggleButton>
        </ToggleButtonGroup>
        <Box>
          <DailyPicker
            view={view}
            week={week}
            setWeek={setWeek}
            setWeekStart={setWeekStart}
            setWeekEnd={setWeekEnd}
            month={month}
            setMonth={setMonth}
            year={year}
            setYear={setYear}
            dateStart={dateStart}
            setDateStart={setDateStart}
            dateEnd={dateEnd}
            setDateEnd={setDateEnd}
          />
        </Box>
        <OneTapButton title="Generate Report" subtitle="Create a report to show daily data" onClick={handleModalOpen} />
      </Box>
      {/* Metric Selectors */}
      <Box display="flex" flexDirection="column">
        <Box mt={2} mb={0}>
          <StyledTypography variant="h6" mb={1}>
            Select Metrics
          </StyledTypography>
          {/* MultiSelector */}
          <Box bgcolor="#F5F5F5" borderRadius={1} mb={2} height="70px">
            <MetricsSelector
              selectedMetrics={selectedMetrics}
              onChange={setSelectedMetrics}
            />
          </Box>
        </Box>
      </Box>

      {/* Charts */}
      {loading ? (
        <Stack direction="row" justifyContent="center" mt={15}>
          <Box width="100%">
            <LinearProgress />
          </Box>
        </Stack>
      ) : (
        <Stack spacing={3} direction="column" mb={3}>
          {selectedMetrics.includes("Step Count") && <StepCount data={data} averageData={averageData} view={view} weekStart={weekStart} weekEnd={weekEnd} month={month} year={year} dateStart={dateStart} dateEnd={dateEnd} setPdfCharts={setPdfCharts} ref={stepCountRef} />}
          {selectedMetrics.includes("Walking Time") && <WalkingTime data={data} averageData={averageData} view={view} weekStart={weekStart} weekEnd={weekEnd} month={month} year={year} dateStart={dateStart} dateEnd={dateEnd} setPdfCharts={setPdfCharts} />}
          {selectedMetrics.includes("Stride Length") && <StrideLength data={data} averageData={averageData} view={view} weekStart={weekStart} weekEnd={weekEnd} month={month} year={year} dateStart={dateStart} dateEnd={dateEnd} setPdfCharts={setPdfCharts} />}
          {selectedMetrics.includes("Knee Range Motion") && <KneeRangeMotion data={data} averageData={averageData} view={view} weekStart={weekStart} weekEnd={weekEnd} month={month} year={year} dateStart={dateStart} dateEnd={dateEnd} onRendered={onKneeRangeMotionChartRendered} />}
        </Stack>
      )}

      {/* Download Data */}
      {/* <Box display="flex" flexDirection="row" justifyContent="flex-start">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>Dowload page in</Typography>
        </Box>
        <CustomizedButton variant="text">PDF</CustomizedButton>
        <Divider orientation="vertical" variant="middle" flexItem />
        <CustomizedButton variant="text">CSV</CustomizedButton>
      </Box> */}
      <Box>
        <Modal
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={openModal}
        >
          <Box sx={{ ...ModalStyle, position: 'relative' }} display="flex" flexDirection="column" gap={1.5}>
            <IconButton
              aria-label="close"
              onClick={handleModalClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <StyledTypography variant="h7">report content:</StyledTypography>
            <Box bgcolor="#F5F5F5" padding={2} display="flex" gap={1} borderRadius={1} width="100%">
              <Typography>
                {view === "week" ? `Weekly Report for:` : view === "month" ? `Monthly Report for:` : view === "custom" ? `Custom Date Report for:` : `Yearly Report for:`}
              </Typography>
              <Typography>
                {view === "week" ?
                  `${weekStart.toDateString()} - ${weekEnd.toDateString()}` :
                  view === "month" ?
                    formatDateString(month, "month") :
                    view === "custom" ?
                      `${dateStart.toDateString()} - ${dateEnd.toDateString()}` :
                      `${year.getFullYear()}`
                }
              </Typography>
            </Box>
            <StyledTypography variant="h7">choose metrics:</StyledTypography>
            <Box bgcolor="#F5F5F5" borderRadius={1} width="100%">
              <MetricsSelector
                selectedMetrics={selectedMetrics}
                onChange={setSelectedMetrics}
              />
            </Box>
            <StyledTypography variant="h7">options:</StyledTypography>
            <Box display="flex" justifyContent="space-between">
              <Box flexBasis="45%">
                <Typography>Date of device supply</Typography>
                <Box bgcolor="#F5F5F5" borderRadius={1} width="100%">
                  <TextField
                    id="outlined-basic"
                    value={dateOfDeviceSupply}
                    onChange={handleDateOfDeviceSupplyChange}
                    fullWidth
                    borderRadius={2}
                    aria-label="none"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                        },
                        '& fieldset': { border: 'none' },
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box flexBasis="45%">
                <Typography>Minutes educating patient</Typography>
                <Box bgcolor="#F5F5F5" borderRadius={1} width="100%">
                  <TextField
                    id="outlined-basic"
                    value={minutesEducatingPatient}
                    onChange={hanldeMinutesEducatingPatientChange}
                    fullWidth
                    borderRadius={2}
                    aria-label="none"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                        },
                        '& fieldset': { border: 'none' },
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box flexBasis="45%">
                <Typography>Short Notes</Typography>
                <Box bgcolor="#F5F5F5" borderRadius={1} width="100%">
                  <TextField
                    id="outlined-basic"
                    value={shortNotes}
                    onChange={handleShortNotesChange}
                    fullWidth
                    borderRadius={2}
                    aria-label="none"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                        },
                        '& fieldset': { border: 'none' },
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box flexBasis="45%">
                <Typography>Via</Typography>
                <Box bgcolor="#F5F5F5" padding={1} borderRadius={1}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      name="via"
                      value={via}
                      onChange={handleViaChange}
                    >
                      <FormControlLabel value="In Person" control={<Radio />} label="In Person" />
                      <FormControlLabel value="Phone" control={<Radio />} label="Phone" />
                      <FormControlLabel value="Video Conference" control={<Radio />} label="Video Conference" />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography>PT/Clinician name</Typography>
              <Box bgcolor="#F5F5F5" borderRadius={1} width="100%">
                <TextField
                  id="outlined-basic"
                  value={clinicianName}
                  onChange={handleClinicianNameChange}
                  fullWidth
                  borderRadius={2}
                  aria-label="none"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                      },
                      '& fieldset': { border: 'none' },
                    },
                  }}
                />
              </Box>
            </Box>
            <StyledTypography variant="h7">rename report:</StyledTypography>
            <Box bgcolor="#F5F5F5" borderRadius={1} width="100%">
              <TextField
                id="outlined-basic"
                value={reportName}
                onChange={handleReportNameInputChange}
                fullWidth
                borderRadius={2}
                aria-label="none"
                InputProps={{
                  disableUnderline: true,
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                    },
                    '& fieldset': { border: 'none' },
                  },
                }}
              />
            </Box>
            <Box display="flex" gap={2}>
              <Button variant="outlined">
                <PDFDownloadLink document={<PDFDocument
                  patientInfo={patientInfo}
                  dateOfDeviceSupply={dateOfDeviceSupply}
                  minutesEducatingPatient={minutesEducatingPatient}
                  via={via}
                  pdfCharts={pdfCharts}
                  view={view}
                  weekStart={weekStart}
                  weekEnd={weekEnd}
                  month={month}
                  year={year}
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  data={data}
                  avgMetrics={avgMetrics}
                  shortNotes={shortNotes}
                  clinicianName={clinicianName}
                />}
                  fileName={`${reportName}.pdf`}
                >
                  {({ blob, url, loading, error }) => (loading ? 'Generating document...' : 'Download document')}
                </PDFDownloadLink>
              </Button>
              <Button variant="outlined" onClick={handleModalClose}>Cancel</Button>
            </Box>
          </Box>
        </Modal>
      </Box >
    </Box >
  );
};

export default DailyData;
