import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import RefreshIcon from '@mui/icons-material/Refresh';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";

const columns = [
  { field: "date", headerName: "Date", flex: 1 },
  { field: "timePeriod", headerName: "Time", sortable: false, flex: 1 },
  {
    field: "notes",
    headerName: "Notes",
    sortable: false,
    flex: 1,
    renderCell: (params) => {
      return params.value == "" ? params.row.clinician_notes : params.value;
    }
  },
  { field: "deviceSide", headerName: "Leg", sortable: false, flex: 1 },
  { field: "sessionType", headerName: "Type", sortable: false, flex: 1 },
];

const CustomToolbar = ({ onRefresh }) => {
  return (
    <GridToolbarContainer>
      <GridToolbar />
      <GridToolbarQuickFilter />
      <Box sx={{ flexGrow: 1 }} />
      <Button
        color="primary"
        variant="outlined"
        onClick={onRefresh}
        startIcon={<RefreshIcon />}
        size="small"
      >
        Refresh
      </Button>
    </GridToolbarContainer>
  );
};

const DataGridDemo = ({ data, handleRefresh }) => {
  const { patientId } = useParams();
  const navigate = useNavigate();

  const handleRowClick = (trialId) => {
    navigate(`/dashboard/${patientId}/analyze/${trialId}`);
  }

  return (
    <Box
      sx={{
        "& .MuiDataGrid-root": {
          // border: "none",
        },
        "& .MuiDataGrid-cell": {
          // borderBottom: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#BBDDE0",
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          // backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          // borderTop: "none",
          // backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          // color: `${colors.greenAccent[200]} !important`,
        },
      }}
    >
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row.id}
        onRowClick={(param) => handleRowClick(param.row.id)}
        sx={{
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
            cursor: 'pointer',
          },
          "& .MuiDataGrid-toolbar": {
            color: "#FF0000", // Example color, change it as per your requirement.
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          boxShadow: 2,
        }}
        rowHeight={45}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 15, 20]}
        disableRowSelectionOnClick
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            onRefresh: handleRefresh,
          },
          panel: {
            sx: {
              "& .MuiTypography-root": {
                // color: 'dodgerblue',
                // fontSize: 20,
              },
              "& .MuiDataGrid-filterForm": {
                // bgcolor: 'lightblue',
              },
            },
          },
        }}
      />
    </Box>
  );
}

export default DataGridDemo;