import {
    Stack,
    Box,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDashboardContext } from "../../contexts/DashboardContext";
import { formatTimestamp } from "../../utils/timeUtils";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

const TrialPicker = ({
    selectedDate,
    setSelectedDate,
    setCurrentTrialId,
    currentTrialId,
    width,
}) => {
    const { allTrials, latestTrialId } = useDashboardContext();
    const [selectedTrialIndex, setSelectedTrialIndex] = useState(null);

    const selectedDay = dayjs(selectedDate).startOf('day');

    const availableTrialsOnSelectedDate = allTrials.filter((trial) => {
        const trialDay = dayjs(trial.date).startOf('day');
        return selectedDay.isSame(trialDay);
    });

    const shouldDisableDate = (date) => {
        return !allTrials.some((trial) => {
            const trialDate = dayjs(trial.date);
            return trialDate.isSame(date, 'day');
        });
    };

    // Handle the selection change in the Select component
    const handleTrialSelect = (event) => {
        const selectedIndex = event.target.value;
        if (selectedIndex >= 0) {
            const selectedTrial = availableTrialsOnSelectedDate[selectedIndex];
            setCurrentTrialId(selectedTrial.id);
        }
    };

    useEffect(() => {
        if (selectedDate && availableTrialsOnSelectedDate.length > 0 && selectedTrialIndex === null) {
            setSelectedTrialIndex(0);
            setCurrentTrialId(availableTrialsOnSelectedDate[0].id);
        }
    }, [selectedDate, availableTrialsOnSelectedDate, selectedTrialIndex, setCurrentTrialId]);

    useEffect(() => {
        const savedTrialId = currentTrialId;
        if (savedTrialId) {
            const savedTrialIndex = availableTrialsOnSelectedDate.findIndex(trial => trial.id === savedTrialId);
            if (savedTrialIndex >= 0) {
                setSelectedTrialIndex(savedTrialIndex);
            }
        }
    }, [currentTrialId, availableTrialsOnSelectedDate]);


    // const availableTrialsOnSelectedDate = allTrials.filter((trial) => {
    //     const trialDate = dayjs(trial.date);
    //     return trialDate.isSame(selectedDate, "day");
    // });

    // const shouldDisableDate = (date) => {
    //     return !allTrials.some((trial) => {
    //         const trialDate = dayjs(trial.date);
    //         return trialDate.isSame(date, "day");
    //     });
    // };

    // // Handle the selection change in the Select component
    // const handleTrialSelect = (event) => {
    //     const selectedIndex = event.target.value; // Get the selected index
    //     if (selectedIndex >= 0) {
    //         // Ensure a valid index is selected
    //         const selectedTrial = availableTrialsOnSelectedDate[selectedIndex]; // Get the selected trial
    //         setCurrentTrialId(selectedTrial.id);
    //     }
    // };

    // useEffect(() => {
    //     const savedTrialId = currentTrialId;
    //     if (savedTrialId) {
    //         const savedTrialIndex = availableTrialsOnSelectedDate.findIndex(trial => trial.id === savedTrialId);
    //         if (savedTrialIndex >= 0) {
    //             setSelectedTrialIndex(savedTrialIndex);  // Set the selected trial index for the dropdown
    //         }
    //     } else if (availableTrialsOnSelectedDate.length > 0) {
    //         setSelectedTrialIndex(0);
    //     }
    // }, [availableTrialsOnSelectedDate]);


    return (
        <Box>
            <Box display="flex" justifyContent="space-between" width={width}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {/* Date Picker */}
                    <DatePicker
                        label="Select Date"
                        value={selectedDate}
                        onChange={(newValue) => {
                            setSelectedDate(newValue);
                        }}
                        shouldDisableDate={shouldDisableDate}
                        sx={{ maxWidth: 200 }}
                    />
                </LocalizationProvider>
                {/* Trial Picker */}
                <Box sx={{ minWidth: 200 }}>
                    <FormControl fullWidth>
                        {/* <InputLabel id="select-trial-label">Select Trial</InputLabel> */}
                        <Select
                            labelId="select-trial-label"
                            id="select-trial"
                            onChange={handleTrialSelect}
                            value={selectedTrialIndex}
                            align="center"
                        >
                            {availableTrialsOnSelectedDate.map((trial, index) => (
                                <MenuItem divider={true} key={index} value={index}>
                                    {trial.startTimestamp}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
        </Box>
    );
};

export default TrialPicker;
