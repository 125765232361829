import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import '../../css/login.css';
import assets from '../../assets';
import { login as apiLogin, getUserByToken } from "../../utils/fastAPI";
import CustomizedSnackbar from '../../components/CustomizedSnackbar';
import { useAuth } from '../../AuthProvider';

function LoginPage() {
  const formik = useFormik({
    initialValues: {
      email: "Enter your email",
      password: "Enter your Password",
    }
  })

  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { values, setFieldValue } = formik;
  const navigate = useNavigate();
  const [modifiedE, setModifiedE] = useState(false);
  const [modifiedP, setModifiedP] = useState(false);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [message, setMessage] = useState('');
  const { login } = useAuth();

  const inputStyle = { width: "100%", height: "40px", fontSize: "16px", marginBottom: "20px" }
  const placeholderStyle = { color: 'grey', opacity: '0.8' }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { email, password } = values;
      const loginResponse = await apiLogin(email, password);
      if (loginResponse && loginResponse.access_token) {
        const token = loginResponse.access_token;
        localStorage.setItem('token', token);
        const user = await getUserByToken(token);

        login(token, user.id);
        localStorage.setItem('clinicianId', user.clinicianId);
        localStorage.setItem('email', user.email);
        localStorage.setItem('name', user.name);

        setSeverity('success');
        setMessage('Log in successfully!');
        setOpen(true);

        console.log("Log in successfully!");
        navigate('/patients');

      } else {
        setSeverity('error');
        setMessage('Failed to log in! Please check your email and password.');
        setOpen(true);
        // console.log("Failed to log in!")
      }
    } catch (error) {
      setSeverity('error');
      setMessage('Failed to log in! Please check your email and password.');
      setOpen(true);
      // console.error('There was an error logging in:', error);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (<div id="login-page">
    <section id="login-container">
      <div style={{ textAlign: "center" }}>
        <img src={assets.images.logo} alt="Logo" style={{ width: '400px', height: '150px', marginBottom: "40px" }} />
      </div>

      <form onSubmit={handleSubmit} id="login-form">

        <label style={{ fontSize: "16px", display: "block", fontWeight: "bold", marginBottom: "8px" }}> Email </label>
        <input
          onChange={e => {
            setFieldValue("email", e.target.value);
            setModifiedE(true);
          }}
          className="login-input"
          value={values.email}
          onFocus={() => { if (!modifiedE) setFieldValue("email", "") }}
          style={Object.assign({}, inputStyle, modifiedE ? null : placeholderStyle)}
          onBlur={() => {
            if (values.email === "") {
              setFieldValue("email", "Enter your Email");
              setModifiedE(false);
            }
          }}
        />

        <label style={{ fontSize: "16px", display: "block", fontWeight: "bold", marginBottom: "8px" }}> Password </label>

        <div style={{ display: "flex" }}>
          <input
            onChange={e => {
              setFieldValue("password", e.target.value);
              setModifiedP(true);
            }}
            className="login-input"
            type={showPassword ? "text" : "password"}
            style={Object.assign({}, inputStyle, modifiedP ? null : placeholderStyle)}
            value={values.password}
            onFocus={() => { if (!modifiedP) setFieldValue("password", "") }}
            onBlur={() => {
              if (values.password === "") {
                setFieldValue("password", "Enter your Password");
                setModifiedP(false);
              }
            }}
          />
          <div style={{ width: "10px" }}></div>

          <button type="button" onClick={() => setShowPassword(!showPassword)} style={{ height: "40px", fontSize: "16px", marginBottom: "30px", width: "80px", fontWeight: "400" }}>
            {showPassword ? "Hide" : "Show"}
          </button>
        </div>

        {incorrectPassword && <div className='error-message'>Email and password do not match.</div>}
        <div className='button-wrapper'>
          <button className="blue-button" type="submit" style={{ marginBottom: "20px" }}>
            Log In
          </button>
          <span style={{ textAlign: 'center' }}>Don't have an account?
            <Link to="/signup" style={{ marginLeft: "5px", color: '#63C7CF' }}>
              Sign up
            </Link>
          </span>
        </div>
      </form>
    </section>
    <section id="image-container">
      {/* <img src="https://images.squarespace-cdn.com/content/v1/5f6bccd37f6369040294ef40/3ccbb190-5dd3-4f03-a911-306cf7a5ec35/shutterstock_1476961856+copy+%281%29.jpg?format=1500w"></img> */}
    </section>
    <CustomizedSnackbar open={open} onClose={handleClose} severity={severity} message={message} />
  </div>
  );
}

export default LoginPage;