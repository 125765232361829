import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import AppWrapper from './AppWrapper';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import App from './App'

document.addEventListener("DOMContentLoaded", () => {
  const root = document.getElementById("root");
  ReactDOM.render(<AppWrapper />, root);
});