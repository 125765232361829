import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { formatTimestamp } from '../../utils/timeUtils';
import LogoImage from '../../assets/images/croppedlogo.png';
import { formatDateString } from "../../utils/timeUtils";
import { getTotalStepCounts } from "../../utils/dataFetcher";
import { getTotalValidDays } from "../../utils/dataFetcher";

const styles = StyleSheet.create({
    headerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: '#F4F5F7',
        borderRadius: 3,
    },
    headerInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    headerLogoContainer: {
        flexDirection: 'column',
    },
    headerLogo: {
        width: 80,
    },
    headerTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#000000',
        marginBottom: 2,
    },
    headerInfo: {
        fontSize: 12,
        color: '#000000',
        marginBottom: 2,
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: 20,
    },
    section: {
        flexGrow: 1,
    },
    header: {
        fontSize: 18,
        marginTop: 3,
        marginBottom: 3,
        fontWeight: 'bold',
        color: '#2C3E50'
    },
    info: {
        fontSize: 14,
        marginBottom: 5,
        color: '#34495E',
        marginLeft: 10
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderColor: 'white',
    },
    tableRow: {
        flexDirection: 'row',
        borderStyle: 'solid',
        borderColor: 'white',
        borderWidth: 1,
        backgroundColor: '#F4F5F7',
    },
    tableColHeader: {
        width: '50%',
        backgroundColor: '#BCDDE0',
        padding: 5,
    },
    tableCol: {
        width: '50%',
        padding: 5,
    },
    tableCellHeader: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#000000',
    },
    tableCell: {
        fontSize: 12,
        color: '#000000',
    },
    imageContainer: {
        marginBottom: 1,
    },
    chartImage: {
        width: 'auto',
        height: '50%',
        border: '1px solid #000000',
        borderRadius: 5,
    },
    imageTitle: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 5,
        marginTop: 20,
    },
    trainingContainer: {
        marginTop: 5,
    },
    trainingText: {
        fontSize: 12,
        color: '#000000',
    },
    clinicianName: {
        position: 'absolute',
        bottom: 10,
        right: 20,
        fontSize: 12,
        color: '#34495E',
    },
});

const PDFDocument = ({
    patientInfo,
    pdfCharts,
    dateOfDeviceSupply,
    minutesEducatingPatient,
    via,
    view,
    weekStart,
    weekEnd,
    month,
    year,
    dateStart,
    dateEnd,
    data,
    avgMetrics,
    shortNotes,
    clinicianName
}) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                {/* Header */}
                <View style={styles.headerContainer}>
                    <View style={styles.headerInfoContainer}>
                        <Text style={styles.headerTitle}>Patient Trial Report</Text>
                        <Text style={styles.headerInfo}>
                            Clinic: Evolution PT{'\n'}
                            Generated Date: {formatTimestamp(new Date())}
                        </Text>
                    </View>
                    <View style={styles.headerLogoContainer}>
                        <Image style={styles.headerLogo} src={LogoImage} />
                    </View>
                </View>

                <View style={styles.trainingContainer}>
                    <Text style={styles.trainingText}>
                        Training: Patient/Caregiver educated on use of the EvoWalk device and app. EvoWalk app downloaded to patient’s mobile device. Patient/caregiver demonstrated competence in using the EvoWalk.
                    </Text>
                </View>

                <View style={styles.titleContainer}>
                    <Text style={styles.header}>Patient Information</Text>
                </View>
                {/* Information table */}
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Information</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Content</Text>
                        </View>
                    </View>
                    {/* Table Rows */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Patient Name</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{patientInfo.name}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Patient Email</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{patientInfo.email}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Affectted Leg</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{patientInfo.affectedLeg}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Condition</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{patientInfo.conditionDescription}</Text>
                        </View>
                    </View>
                </View>

                {/* Device table */}
                <View style={styles.titleContainer}>
                    <Text style={styles.header}>Device Description</Text>
                </View>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Information</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Content</Text>
                        </View>
                    </View>
                    {/* Table Rows */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Number of walking days</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{getTotalValidDays(data, view)} days</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Date of devide supply</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{dateOfDeviceSupply}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Minutes educating patient</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{minutesEducatingPatient}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Via</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{via}</Text>
                        </View>
                    </View>
                </View>

                <View style={styles.titleContainer}>
                    <Text style={styles.header}>
                        Data Report:
                        {view === "week"
                            ? `${formatDateString(weekStart, "date")} - ${formatDateString(weekEnd, "date")}` + ` ${weekStart.getFullYear()}`
                            : view === "month"
                                ? formatDateString(month, view)
                                : view === "custom"
                                    ? `${formatDateString(dateStart, "date")} - ${formatDateString(dateEnd, "date")}`
                                    : formatDateString(year, view)}
                    </Text>
                </View>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Metric</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Value</Text>
                        </View>
                    </View>
                    {/* Table Rows */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Total Steps</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{getTotalStepCounts(data)} steps</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Average Steps</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{avgMetrics.averageStepCount} steps</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Average Walking Time</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{avgMetrics.averageWalkingTime} m/s</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Average Knee Flexion</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{avgMetrics.averageKneeRangeMotion} degree</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Average Stride Length</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{avgMetrics.averageStrideLength} m</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Short Notes</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{shortNotes}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={{ flexGrow: 1 }}>
                <Text style={styles.clinicianName}>{`Clinician: ${clinicianName}`}</Text>
            </View>

        </Page>
        {/* <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <Text style={styles.header}>Graphs</Text>
                {pdfCharts && pdfCharts.map((item, index) => (
                    <View key={index} style={styles.imageContainer}>
                        <Image src={item.src} style={styles.chartImage} />
                    </View>
                ))}
            </View>
        </Page> */}
    </Document >
);


export default PDFDocument;