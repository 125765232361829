import React from "react";
import Box from "@mui/material/Box";
import Paper from '@mui/material/Paper';
import TimerIcon from "@mui/icons-material/Timer";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LegIcon from "@mui/icons-material/Accessibility";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { formatTimestamp } from "../../utils/timeUtils";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         backgroundColor: "#BBDDE0",
//         color: theme.palette.common.black,
//         fontSize: 16,
//     },
//     [`&.${tableCellClasses.body}`]: {
//         fontSize: 16,
//     },
//     height: 58,
//     border: 'none'
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     "&:nth-of-type(even)": {
//         backgroundColor: "#F4F5F7",
//         borderBottom: "none",
//     },
//     "&:nth-of-type(odd)": {
//         backgroundColor: "#F4F5F7",
//         borderBottom: "none",
//     },
// }));

function TrialGrid({ selectedTrialInfo, isLoading, summaryData }) {

    return (
        <Box width="95%" component={Paper}>
            <List dense={false} sx={{ width: '100%', bgcolor: '#F5F5F5', borderRadius: "5px" }}>
                <ListItem>
                    <ListItemIcon>
                        <TimerIcon />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }} primary="StartTime" />
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" width="100%">
                            <Skeleton variant="text" width="50%" />
                        </Box>
                    ) : (
                        <ListItemText primary={selectedTrialInfo?.startTimestamp} />
                    )}
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemIcon>
                        <LegIcon />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }} primary="LegSide" />
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" width="100%">
                            <Skeleton variant="text" width="50%" />
                        </Box>
                    ) : (
                        <ListItemText primary={summaryData?.deviceLocation} />
                    )}
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemIcon>
                        <DescriptionOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }} primary="SessionType" />
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" width="100%">
                            <Skeleton variant="text" width="50%" />
                        </Box>
                    ) : (
                        <ListItemText primary={selectedTrialInfo?.sessionType || "N/A"} />
                    )}
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemIcon>
                        <EventNoteIcon />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ fontWeight: 'bold' }} primary="Notes" />
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" width="100%">
                            <Skeleton variant="text" width="50%" />
                        </Box>
                    ) : (
                        <ListItemText primary={selectedTrialInfo?.notes == "" ? selectedTrialInfo?.clinician_notes : selectedTrialInfo?.notes} />
                    )}
                </ListItem>
            </List>
        </Box>
    );
}

export default TrialGrid;
