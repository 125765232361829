import * as React from "react";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";

function CustomizedSearchBar({
  placeholderText = "Search",
  searchTerm,
  setSearchTerm,
}) {
  return (
    <>
      <Box
        display="flex"
        border="1px solid black"
        borderRadius={3}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        padding={1}
      >
        <Box display="flex" alignItems="center" ml={1} mr={1}>
          <SearchIcon />
        </Box>
        <InputBase
          placeholder={placeholderText}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
          sx={{ flexGrow: 1 }}
        />
        <Box display="flex" alignItems="center" mr={1}>
          {searchTerm && <ClearIcon sx={{ cursor: 'pointer' }} onClick={() => setSearchTerm("")} />}
        </Box>
      </Box>
    </>
  );
}

export default CustomizedSearchBar;