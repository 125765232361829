import React from 'react';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

function StatusIndicator({ isReady }) {
    if (isReady) {
        return <Chip label="Ready" icon={<CheckCircleIcon />} color="success" />;
    }
    return <Chip label="Not Ready" icon={<CancelIcon />} color="error" />;
}

export default StatusIndicator;