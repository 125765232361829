import React, { useEffect } from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    useLocation
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import LoginPage from './pages/Login/LoginPage';
import ProtectedRoute from './ProtectedRoute';
import Patients from './pages/PatientsList';
import PatientHomePage from './pages/PatientHomePage';
import DashboardLayout from './components/layout/DashboardLayout';
import AnalyzeTrial from './pages/AnalyzeTrial';
import CompareTrials from './pages/CompareTrials';
import DailyData from './pages/DailyData';
import Reports from './pages/Reports';
import Feedback from './pages/Feedback';
import { DashboardProvider } from './contexts/DashboardContext';

const history = createBrowserHistory();

function App() {
    return (
        <BrowserRouter history={history}>
            <DashboardProvider>
                <Routes>
                    <Route exact path="/" element={<LoginPage />} />
                    <Route path="/" element={<ProtectedRoute />}>
                        <Route path="/patients" element={<Patients />} />
                        <Route path="/dashboard/:patientId" element={<DashboardLayout />}>
                            <Route index path="" element={<PatientHomePage />} />
                            <Route path="analyze/:trialId" element={<AnalyzeTrial />} />
                            <Route path="compare/:trialId1/vs/:trialId2" element={<CompareTrials />} />
                            <Route path="daily" element={<DailyData />} />
                            <Route path="reports/:trialId" element={<Reports />} />
                            <Route path="feedback" element={<Feedback />} />
                        </Route>
                    </Route>
                </Routes>
            </DashboardProvider>
        </BrowserRouter>
    )
}

export default App;
