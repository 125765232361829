import React from "react";
import { Box, Typography, Tooltip, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import WalkingTimeChart from "./WalkingTimeChart";
import TimelineIcon from '@mui/icons-material/Timeline';
import BarChartIcon from '@mui/icons-material/BarChart';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { formatDateString } from "../../utils/timeUtils";

const WalkingTime = ({ data, averageData, view, weekStart, weekEnd, month, year, dateStart, dateEnd, setPdfCharts }) => {
  const [chartType, setChartType] = useState("bar");

  // Toggle chart type
  const toggleChartType = () => {
    setChartType(chartType === "line" ? "bar" : "line");
  };

  return (
    <Stack direction="row" spacing={3} alignItems="stretch">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          p={3}
          m={2}
          border="1px solid #ddd"
          borderRadius="8px"
          boxShadow={2}
          height="100%"
          width="30%"
          bgcolor="#FEFBE9"
        >
          <Box position="absolute" top={0} right={0}>
            <Tooltip title="Walking time measures the total duration of walking activity per day. It helps in assessing the amount of daily exercise and endurance.">
              <IconButton aria-label="info">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Box>
          {/* <Typography ml={1} mt={1} variant="h5" gutterBottom>Walking Time</Typography> */}
          <Box ml={3} mt={8} mb={1}>
            <Box mb={1}>
              <Typography variant="h6" gutterBottom>
                {view === "week" ? "Current week" : view === "month" ? "Current month" : view === "custom" ? "Custom Date Range" : "Current year"}
              </Typography>
              <Typography variant="subtitle1" style={{ lineHeight: "2.0" }}>
                {view === "week"
                  ? `${formatDateString(weekStart, "date")} - ${formatDateString(weekEnd, "date")}` + ` ${weekStart.getFullYear()}`
                  : view === "month"
                    ? formatDateString(month, view)
                    : view === "custom"
                      ? `${formatDateString(dateStart, "date")} - ${formatDateString(dateEnd, "date")}`
                      : formatDateString(year, view)}
              </Typography>
            </Box>
            <Typography variant="h6" gutterBottom>
              Average per day
            </Typography>
            <Typography variant="body1" style={{ lineHeight: "2.0" }}>
              <span style={{ fontSize: "1.5em" }}>{averageData.averageWalkingTime}</span> minutes
            </Typography>
          </Box>
        </Box>
        {/* Chart */}
        <Stack width="70%" height="100%" justifyContent="space-between">
          <Box display="flex" justifyContent="space-between">
            <ToggleButtonGroup size="small" value={chartType} exclusive onChange={toggleChartType}>
              <ToggleButton value="bar" aria-label="bar">
                <BarChartIcon />
              </ToggleButton>
              <ToggleButton value="line" aria-label="line">
                <TimelineIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box>
            <WalkingTimeChart data={data} view={view} weekStart={weekStart} weekEnd={weekEnd} month={month} year={year} dateStart={dateStart} dateEnd={dateEnd} chartType={chartType} setPdfCharts={setPdfCharts} />
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};

export default WalkingTime;
