import React, { useState } from 'react';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

function OneTapButton(props) {
    const [isHovered, setIsHovered] = useState(false);

    const baseButtonStyle = {
        flexGrow: 0,
        margin: '8px',
        backgroundColor: '#F5F5F5',
        border: '2px solid #C0C0C0',
        borderRadius: '8px',
        padding: '10px 20px',
        fontSize: '17px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center'
    };

    const baseTextStyle = {
        margin: 1,
        padding: 0,
    };

    const firstLineStyle = {
        ...baseTextStyle,
        fontWeight: 'bold',
    };

    const secondLineStyle = {
        ...baseTextStyle,
        fontWeight: 'normal',
        fontSize: '14px',
    };

    const hoverStyle = {
        ...baseButtonStyle,
        backgroundColor: '#E0E0E0' // Slightly darker background on hover
    };

    return (
        <button
            style={isHovered ? hoverStyle : baseButtonStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={props.onClick}
        >
            <span style={firstLineStyle}>
                <AutoAwesomeIcon fontSize="normal" /> { }
                {props.title}
            </span>
            <span style={secondLineStyle}>{props.subtitle}</span>
        </button>
    );
}

export default OneTapButton;