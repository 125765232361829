import React from "react";
import Box from "@mui/material/Box";
import ReactEcharts from "echarts-for-react";
import { useEffect, useRef, useMemo } from "react";
import { formatDateString } from "../../utils/timeUtils";

const StrideLengthChart = ({ data, view, weekStart, weekEnd, month, year, dateStart, dateEnd, chartType, setPdfCharts }) => {
  const chartRef = useRef(null);
  const imageCapturedRef = useRef(false);

  useEffect(() => {
    setPdfCharts([]);
    imageCapturedRef.current = false;
  }, [view, setPdfCharts]);

  const getDayOfWeek = (index) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[index % 7];
  };

  const getMonthName = (index) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[index % 12];
  };

  const adjustBarWidth = (dataLength) => {
    if (dataLength <= 12) {
      return 20;
    } else if (dataLength <= 20) {
      return 15;
    } else if (dataLength <= 40) {
      return 10;
    } else {
      return 5;
    }
  };

  const specificXAxisLabels = [1, 5, 10, 15, 20, 25, 30];
  let xAxisData;
  let yAxisData;
  switch (view) {
    case "month":
      xAxisData = data.map((item, index) => {
        if (specificXAxisLabels.includes(index + 1)) {
          return index + 1;
        } else {
          return "";
        }
      });
      yAxisData = data.map((item) => item.step_length);
      break;
    case "year":
      // For yearly view, use month names
      xAxisData = data.map((_, index) => getMonthName(index));
      yAxisData = data.map((item) => {
        if (item.valid_days === 0 || item.step_length === 0) {
          return 0;
        } else {
          return item.step_length;
          // return (item.step_length / item.valid_days).toFixed(2);
        }
      });
      break;
    case "custom":
      xAxisData = data.map((item) => item.date);
      yAxisData = data.map((item) => item.step_length);
      break;
    default:
      xAxisData = data.map((_, index) => getDayOfWeek(index));
      yAxisData = data.map((item) => item.step_length);
  }

  const getTitleText = () => {
    switch (view) {
      case "month":
        return "Stride Length" + " [" + formatDateString(month, view) + "]";
      case "year":
        return "Stride Length " + formatDateString(year, view);
      case "custom":
        return "Stride Length " + "[" + formatDateString(dateStart, "date") + " - " + formatDateString(dateEnd, "date") + "]";
      default:
        return "Stride Length " + "[" + formatDateString(weekStart, "date") + " - " + formatDateString(weekEnd, "date") + " " + weekStart.getFullYear() + "]";
    }
  };

  const option = useMemo(() => ({
    title: {
      text: getTitleText(),
      left: "center",
    },
    color: ["#9FD3D3"],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        label: {
          show: true
        }
      }
    },
    xAxis: {
      type: "category",
      data: xAxisData || [],
      nameLocation: "middle",
      nameGap: 50,
      nameTextStyle: {
        fontSize: 15,
      },
      axisLabel: {
        // rotate: 30,
        fontSize: 12, // Reduce font size if necessary
      },
      axisLine: {
        lineStyle: {
          color: "#000000",
        },
      },
    },
    yAxis: {
      type: "value",
      name: "meters",
      nameLocation: "middle",
      nameGap: 50,
      nameTextStyle: {
        fontSize: 15,
      },
      axisLine: {
        lineStyle: {
          color: "#000000",
        },
      },
    },
    calculable: true,
    toolbox: {
      show: true, // Set to true to enable the toolbox
      feature: {
        magicType: { show: true, type: ['line', 'bar'] },
        saveAsImage: { // Enable the "Save As Image" feature
          show: true, // Show the "Save As Image" icon
          title: "Save As Image", // Set the tooltip title for the "Save As Image" icon
          name: "stride_length_chart", // Set the default filename for the downloaded image
          excludeComponents: ["toolbox"], // Exclude the toolbox icon from the downloaded image
          pixelRatio: 2, // Set the pixel ratio for the downloaded image (default is 1)
        },
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: (params) => {
        if (view === "week" || view === "month" || view === "custom") {
          const fullDate = data[params.dataIndex].date;
          return `${fullDate}<br/>${params.seriesName}: ${params.value}`;
        } else {
          const month = getMonthName(params.dataIndex);
          return `${month}<br/>${params.seriesName}: ${params.value}`;
        }
      }
    },
    series: [
      {
        name: "stride length",
        data: yAxisData || [],
        type: chartType,
        smooth: true,
        barMaxWidth: adjustBarWidth(data.length),
        lineStyle: {
          normal: {
            width: 4,
            type: "solid",
          },
        },
        areaStyle: {
          color: '#E9EFFD',
        },
        symbolSize: 8,
        label: {
          show: view === "month" || view === "custom" ? false : true,
          position: "top",
          textStyle: {
            fontSize: 15,
          },
        }
      },
    ],
  }), [data, view, weekStart, weekEnd, month, year, dateStart, dateEnd, chartType]);

  useEffect(() => {
    const resizeListener = () => {
      if (chartRef.current) {
        chartRef.current.getEchartsInstance().resize();
      }
    };
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const handleChartFinished = () => {
    if (chartRef.current && !imageCapturedRef.current) {
      const imageURI = chartRef.current.getEchartsInstance().getDataURL();
      // console.log(imageURI);
      setTimeout(() => {
        setPdfCharts(prev => [...prev, { src: imageURI, title: getTitleText() }]);
        imageCapturedRef.current = true; // Prevent further captures
      }, 0);
    }
  };


  const onEvents = {
    'finished': handleChartFinished,
  };

  return (
    <Box>
      <Box width="100%" height="300px" borderRadius={3} boxShadow={2} p={1}>
        <ReactEcharts ref={chartRef} option={option} style={{ height: "100%" }} onEvents={onEvents} />
      </Box>
    </Box>
  );
};

export default React.memo(StrideLengthChart);
