import React, { useState } from "react";
import {
  Stack,
  OutlinedInput,
  MenuItem,
  Chip,
  Select,
  FormControl,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import colorConfigs from "../configs/colorConfigs";

const names = [
  "Gait Cycle",
  "Knee Flexion",
  "Hip Flexion",
  "Walking Probability",
  "Foot Drag Probability",
  "Ankle Speed",
];

const MultiSelector = ({ selectedMetrics, onChange }) => {
  const [selectedNames, setSelectedNames] = useState([]);
  return (
    <FormControl sx={{ width: "100%", height: "100%" }}>
      <Select
        multiple
        value={selectedMetrics}
        onChange={(e) => onChange(e.target.value)}
        input={
          <OutlinedInput
            label="Multiple Select"
            sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
          />
        }
        onDelete={(e) =>
          onChange(selectedMetrics.filter((item) => item !== e.target.value))
        }
        renderValue={(selected) => (
          <Stack gap={1} direction="row" flexWrap="wrap">
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                onDelete={(event) => {
                  event.stopPropagation();
                  onChange(selectedMetrics.filter((item) => item !== value));
                }}
                deleteIcon={
                  <ClearIcon
                    onMouseDown={(event) => event.stopPropagation()}
                    sx={{
                      color: `${colorConfigs.multiselector.chip_text} !important`,
                    }}
                  />
                }
                sx={{
                  backgroundColor: colorConfigs.multiselector.chip_background,
                  color: colorConfigs.multiselector.chip_text,
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  borderRadius: "5px",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              />
            ))}
          </Stack>
        )}
      >
        {names.map((name) => (
          <MenuItem
            key={name}
            value={name}
            sx={{ justifyContent: "space-between" }}
          >
            {name}
            {selectedNames.includes(name) ? <CheckIcon color="info" /> : null}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelector;
