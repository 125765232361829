import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { tableCellClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#BBDDE0",
    color: theme.palette.common.black,
    fontSize: 17,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 17,
  },
  height: 58,
  border: 'none',
  borderRight: '1px solid rgba(224, 224, 224, 1)' // Add a right border to each cell
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#F4F5F7",
    borderBottom: "none",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "#F4F5F7",
    borderBottom: "none",
  },
}));

const SummaryMetricsChart = ({ summaryData, loading }) => {
  const renderTableCells = (metrics) => {
    return metrics.map((metric, index) => (
      <StyledTableCell key={index} align="center">
        {loading ? (
          <Skeleton variant="text" width="80%" />
        ) : (
          `${metric.value} ${metric.unit}`
        )}
      </StyledTableCell>
    ));
  };

  const handleDecimals = (value) => {
    if (Number.isInteger(value)) {
      return value;
    } else {
      return parseFloat(value).toFixed(1);
    }
  };

  // Data for summary metrics
  const metrics = [
    {
      name: "Steps",
      value: handleDecimals(summaryData?.peak_step_count),
      unit: "steps",
    },
    {
      name: "Walk Time",
      value: handleDecimals(summaryData?.walking_time),
      unit: "s",
    },
    {
      name: "Speed",
      value: handleDecimals(summaryData?.speed),
      unit: "m/s",
    },
    {
      name: "Distance",
      value: handleDecimals(summaryData?.distance),
      unit: "m",
    },
    {
      name: "Cadence",
      value: handleDecimals(summaryData?.cadence),
      unit: " / min",
    },
    {
      name: "Stride Length",
      value: handleDecimals(summaryData?.step_length),
      unit: "m",
    },
    {
      name: "Swing %",
      value: handleDecimals(summaryData?.swingRatio),
      unit: "%",
    },
    {
      name: "Stance %",
      value: handleDecimals(summaryData?.stanceRatio),
      unit: "%",
    },
    {
      name: "Knee Flex° Mid Stance",
      value: handleDecimals(summaryData?.midstanceKnee),
      unit: "°",
    },
    {
      name: "Knee Flex° Initial Swing",
      value: handleDecimals(summaryData?.initialswingKnee),
      unit: "°",
    },
    {
      name: "Knee Flex° Mid Swing",
      value: handleDecimals(summaryData?.midswingKnee),
      unit: "°",
    },
    {
      name: "Total Knee ROM°",
      value: handleDecimals(summaryData?.romKnee),
      unit: "°",
    },
    // {
    //   name: "Hip Flex° Mid Stance",
    //   value: parseFloat(
    //     selectedTrialInfo?.summaryData?.hip_flexion_terminal_midstance.toFixed(2)
    //   ),
    //   unit: "°",
    // },
    // {
    //   name: "Hip Flex° Term Stance",
    //   value: parseFloat(
    //     selectedTrialInfo?.summaryData?.hip_flexion_terminal_stance.toFixed(2)
    //   ),
    //   unit: "°",
    // },
    // {
    //   name: "Foot Drag %",
    //   value: parseFloat(selectedTrialInfo?.summaryData?.footdrag.toFixed(2)),
    //   unit: "%",
    // },
    // {
    //   name: "Fall score",
    //   value: parseFloat(selectedTrialInfo?.summaryData?.fall_score.toFixed(2)),
    //   unit: "/ 100",
    // },
  ];

  const firstHalfMetrics = metrics.slice(0, metrics.length / 2);
  const secondHalfMetrics = metrics.slice(metrics.length / 2);

  return (
    <Box position="relative">
      {/* First Row */}
      <TableContainer component={Paper}>
        <Table size="small">
          {/* First Row of Headers */}
          <TableHead>
            <StyledTableRow>
              {firstHalfMetrics.map((metric, index) => (
                <StyledTableCell key={index} align="center">
                  {metric.name}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>

          {/* First Row of Data */}
          <TableBody>
            <StyledTableRow>
              {renderTableCells(firstHalfMetrics)}
              {/* {firstHalfMetrics.map((metric, index) => (
                <StyledTableCell key={index} align="center">
                  {metric.value} {metric.unit}
                </StyledTableCell>
              ))} */}
            </StyledTableRow>
          </TableBody>

          {/* Second Row of Headers */}
          <TableHead>
            <StyledTableRow>
              {secondHalfMetrics.map((metric, index) => (
                <StyledTableCell key={index} align="center">
                  {metric.name}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>

          {/* Second Row of Data */}
          <TableBody>
            <StyledTableRow>
              {renderTableCells(secondHalfMetrics)}
              {/* {secondHalfMetrics.map((metric, index) => (
                <StyledTableCell key={index} align="center">
                  {metric.value} {metric.unit}
                </StyledTableCell>
              ))} */}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default SummaryMetricsChart;
