import Box from '@mui/material/Box';
import Typograph from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import colorConfigs from '../../configs/colorConfigs';
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.common.black,
    backgroundColor: "#F5F5F5",
    "&:hover": {
        backgroundColor: "#FFFFFF",
    },
}));


const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
        '& fieldset': { border: 'none' },
    },
});

const Feedback = () => {
    const [feedback, setFeedback] = useState("");

    const handleInputChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleSendFeedback = () => {
        console.log(feedback);

        setFeedback("");
    };

    return (
        <Box display="flex" flexDirection="column" m={1} justifyContent="center" alignItems="center">
            <Box width="80%" minHeight="500px" boxShadow="initial" bgcolor={colorConfigs.primary} p={5} borderRadius={2} mt={10}>
                <Typograph variant="h6" mb={3}>If you want to tell us something about the platform, it's here :)</Typograph>
                <Box mb={3} bgcolor={colorConfigs.tertiary} borderRadius={2}>
                    <StyledTextField
                        id="outlined-multiline-static"
                        multiline
                        rows={18}
                        fullWidth
                        value={feedback}
                        onChange={handleInputChange}
                        borderRadius={2}
                        aria-label='none'
                    />
                </Box>
                <StyledButton variant='contained' onClick={handleSendFeedback} sx={{ backgroundColor: "#F5F5F5", color: "black" }}>Send</StyledButton>
            </Box>

        </Box>
    );
};

export default Feedback;