import Box from "@mui/material/Box";
import ReactEcharts from "echarts-for-react";
import { useEffect, useRef } from "react";

const KneeFlexChart = ({ rawData, width, height }) => {
    const timeSeries = rawData.slice(1).map(item => item[2] / 1000);
    const kneeFlex = rawData.slice(1).map(item => item[13]);

    const minTime = Math.min(...timeSeries);
    const normalizedTimeSeries = timeSeries.map(time => time - minTime)
    const interval = Math.floor(normalizedTimeSeries.length / 10);

    // Calculate the average of kneeFlex
    const averageKneeFlex = kneeFlex.reduce((a, b) => a + b, 0) / kneeFlex.length;

    // Create an array for the average line data
    const averageLineData = normalizedTimeSeries.map(() => averageKneeFlex);

    const chartRef = useRef(null);

    const option = {
        title: {
            text: 'Knee Flex',
            left: 'left',
        },
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            top: '5%',
            left: 'center'
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                interval: interval,
                formatter: function (value) {
                    return parseFloat(value).toFixed(2);
                }
            },
            data: normalizedTimeSeries || [],
            name: 'Time (s)',
            nameLocation: 'middle',
            nameGap: 50,
            nameTextStyle: {
                fontSize: 15,
            },
            axisLine: {
                lineStyle: {
                    color: '#000000',
                },
            },
        },
        yAxis: {
            type: 'value',
            name: 'Knee_Flex(°)',
            nameLocation: 'middle',
            nameGap: 50,
            nameTextStyle: {
                fontSize: 15,
            },
            axisLine: {
                lineStyle: {
                    color: '#000000',
                },
            },
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {
                    show: true,
                    title: 'Save As Image',
                    name: 'knee_flex_chart',
                    excludeComponents: ['toolbox'],
                    pixelRatio: 2,
                },
            },
        },
        series: [
            {
                name: 'Knee_Flex',
                data: kneeFlex || [],
                type: 'line',
                smooth: true,
                lineStyle: {
                    normal: {
                        width: 4,
                        type: 'solid',
                        color: '#9FD3D3',
                    },
                },
                areaStyle: {
                    color: '#E9EFFD',
                },
                symbolSize: 6,
                itemStyle: {
                    color: '#9FD3D3',
                },
            },
            {
                name: 'Average',
                data: averageLineData,
                type: 'line',
                lineStyle: {
                    normal: {
                        width: 2,
                        type: 'dashed',
                        color: '#D0312D',
                    },
                },
                showSymbol: false,
                symbolSize: 6,
                itemStyle: {
                    color: '#D0312D',
                },
            }
        ],
    };

    useEffect(() => {
        const resizeListener = () => {
            if (chartRef.current) {
                chartRef.current.getEchartsInstance().resize();
            }
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    return (
        <Box width="95%" height="80%" borderRadius={3} boxShadow={5} p={1}>
            <ReactEcharts ref={chartRef} option={option} />
        </Box>
    );
};

export default KneeFlexChart;
