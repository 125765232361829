import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useState } from "react";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
dayjs.extend(isoWeek);

const DailyPicker = ({ view, week, setWeek, setWeekStart, setWeekEnd, month, setMonth, year, setYear, dateStart, setDateStart, dateEnd, setDateEnd }) => {
    const [weekTuple, setWeekTuple] = useState({ start: null, end: null });
    const handleWeekChange = (date) => {
        // Set Sunday as the first day of the week
        const startOfWeek = dayjs(date).startOf('week');
        const endOfWeek = startOfWeek.add(6, 'days');
        setWeekTuple({ start: startOfWeek, end: endOfWeek });
        setWeekStart(new Date(startOfWeek));
        setWeekEnd(new Date(endOfWeek));
    };

    const weekLabel = weekTuple && weekTuple.start && weekTuple.end
        ? `${weekTuple.start.format("MMM D")} - ${weekTuple.end.format("MMM D")}`
        : "Select Week";

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            {view === "week" && (
                <DatePicker
                    label={weekLabel}
                    value={week}
                    onChange={handleWeekChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            )}
            {view === "month" && (
                <DatePicker
                    label="Select Month"
                    views={['year', 'month']}
                    value={month}
                    onChange={(newValue) => { setMonth(newValue) }}
                />
            )}
            {view === "year" && (
                <DatePicker
                    label="Select Year"
                    views={['year']}
                    value={year}
                    onChange={(newValue) => { setYear(newValue) }}
                />
            )}
            {view === "custom" && (
                <Box display="flex" gap={2}>
                    <DatePicker
                        label="Start Date"
                        value={dateStart}
                        onChange={(newValue) => { setDateStart(newValue) }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DatePicker
                        label="End Date"
                        value={dateEnd}
                        onChange={(newValue) => { setDateEnd(newValue) }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Box>
            )}
        </LocalizationProvider>
    );
}

export default DailyPicker;