import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from "@mui/material/Skeleton";
import { tableCellClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme, isFirstColumn }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#BBDDE0",
        color: theme.palette.common.black,
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        fontWeight: isFirstColumn ? 'bold' : 'normal', // Apply bold font weight for the first column
        fontStyle: isFirstColumn ? 'italic' : 'normal', // Apply italic style for the first column
    },
    height: 36,
}));


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#F5F5F5",
        borderBottom: "none",
    },
    "&:nth-of-type(odd)": {
        backgroundColor: "white",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const calculateDifference = (data1, data2) => {
    const diff = data2 - data1;
    if (Number.isInteger(diff)) {
        return diff;
    } else {
        return diff.toFixed(2);
    }
};


const handleDecimals = (value) => {
    if (Number.isInteger(value)) {
        return value;
    } else {
        return parseFloat(value).toFixed(1);
    }
};

const CompareTable = ({ summaryData1, summaryData2, loading }) => {
    const metrics = [
        {
            name: "Steps",
            value1: handleDecimals(summaryData1?.peak_step_count),
            value2: handleDecimals(summaryData2?.peak_step_count),
            value: calculateDifference(
                summaryData1?.peak_step_count,
                summaryData2?.peak_step_count
            ),
            unit: "steps",
        },
        {
            name: "Walking Time",
            value1: handleDecimals(summaryData1?.walking_time),
            value2: handleDecimals(summaryData2?.walking_time),
            value: calculateDifference(
                summaryData1?.walking_time,
                summaryData2?.walking_time
            ),
            unit: "s",
        },
        {
            name: "Speed",
            value1: handleDecimals(summaryData1?.speed),
            value2: handleDecimals(summaryData2?.speed),
            value: calculateDifference(
                summaryData1?.speed,
                summaryData2?.speed
            ),
            unit: "m/s",
        },
        {
            name: "Distance",
            value1: handleDecimals(summaryData1?.distance),
            value2: handleDecimals(summaryData2?.distance),
            value: calculateDifference(
                summaryData1?.distance,
                summaryData2?.distance
            ),
            unit: "m",
        },
        {
            name: "Cadence",
            value1: handleDecimals(summaryData1?.cadence),
            value2: handleDecimals(summaryData2?.cadence),
            value: calculateDifference(
                summaryData1?.cadence,
                summaryData2?.cadence
            ),
            unit: "/min",
        },
        {
            name: "Stride Length",
            value1: handleDecimals(summaryData1?.step_length),
            value2: handleDecimals(summaryData2?.step_length),
            value: calculateDifference(
                summaryData1?.step_length,
                summaryData2?.step_length
            ),
            unit: "m",
        },
        {
            name: "Swing %",
            value1: handleDecimals(summaryData1?.swingRatio),
            value2: handleDecimals(summaryData2?.swingRatio),
            value: calculateDifference(
                summaryData1?.swingRatio,
                summaryData2?.swingRatio
            ),
            unit: "%",
        },
        {
            name: "Stance %",
            value1: handleDecimals(summaryData1?.stanceRatio),
            value2: handleDecimals(summaryData2?.stanceRatio),
            value: calculateDifference(
                summaryData1?.stanceRatio,
                summaryData2?.stanceRatio
            ),
            unit: "%",
        },
        {
            name: "Knee Flex° Mid Stance",
            value1: handleDecimals(summaryData1?.midstanceKnee),
            value2: handleDecimals(summaryData2?.midstanceKnee),
            value: calculateDifference(
                summaryData1?.midstanceKnee,
                summaryData2?.midstanceKnee
            ),
            unit: "°",
        },
        {
            name: "Knee Flex° Initial Swing",
            value1: handleDecimals(summaryData1?.initialswingKnee),
            value2: handleDecimals(summaryData2?.initialswingKnee),
            value: calculateDifference(
                summaryData1?.initialswingKnee,
                summaryData2?.initialswingKnee
            ),
            unit: "°",
        },
        {
            name: "Knee Flex° Mid Swing",
            value1: handleDecimals(summaryData1?.midswingKnee),
            value2: handleDecimals(summaryData2?.midswingKnee),
            value: calculateDifference(
                summaryData1?.midswingKnee,
                summaryData1?.midswingKnee
            ),
            unit: "°",
        },
        {
            name: "Total Knee ROM°",
            value1: handleDecimals(summaryData1?.romKnee),
            value2: handleDecimals(summaryData2?.romKnee),
            value: calculateDifference(
                summaryData1?.romKnee,
                summaryData2?.romKnee
            ),
            unit: "°",
        },
        // {
        //     name: "Hip Flex° Mid Stance",
        //     value1: handleDecimals(selectedTrialInfo1?.summaryData?.hip_flexion_terminal_midstance),
        //     value2: handleDecimals(selectedTrialInfo2?.summaryData?.hip_flexion_terminal_midstance),
        //     value: calculateDifference(
        //         selectedTrialInfo1?.summaryData?.hip_flexion_terminal_midstance,
        //         selectedTrialInfo2?.summaryData?.hip_flexion_terminal_midstance
        //     ),
        //     unit: "°",
        // },
        // {
        //     name: "Hip Flex° Term Stance",
        //     value1: handleDecimals(selectedTrialInfo1?.summaryData?.hip_flexion_terminal_stance),
        //     value2: handleDecimals(selectedTrialInfo2?.summaryData?.hip_flexion_terminal_stance),
        //     value: calculateDifference(
        //         selectedTrialInfo1?.summaryData?.hip_flexion_terminal_stance,
        //         selectedTrialInfo2?.summaryData?.hip_flexion_terminal_stance
        //     ),
        //     unit: "°",
        // },
        // {
        //     name: "Foot Drag %",
        //     value1: handleDecimals(selectedTrialInfo1?.summaryData?.footdrag),
        //     value2: handleDecimals(selectedTrialInfo2?.summaryData?.footdrag),
        //     value: calculateDifference(
        //         selectedTrialInfo1?.summaryData?.footdrag,
        //         selectedTrialInfo2?.summaryData?.footdrag
        //     ),
        //     unit: "%",
        // },
        // {
        //     name: "Fall Score",
        //     value1: handleDecimals(selectedTrialInfo1?.summaryData?.fall_score),
        //     value2: handleDecimals(selectedTrialInfo2?.summaryData?.fall_score),
        //     value: calculateDifference(
        //         selectedTrialInfo1?.summaryData?.fall_score,
        //         selectedTrialInfo2?.summaryData?.fall_score
        //     ),
        //     unit: "/ 100",
        // },
    ];

    return (
        <TableContainer sx={{ width: "80%" }} component={Paper}>
            <Table size="small">
                {/* First Row of Headers */}
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell>Metric</StyledTableCell>
                        <StyledTableCell>Trial1</StyledTableCell>
                        <StyledTableCell>Trial2</StyledTableCell>
                        <StyledTableCell>Difference (Trial2 - Trial1)</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        [...Array(16)].map((_, index) => (
                            <StyledTableRow key={index}>
                                {[...Array(4)].map((_, cellIndex) => (
                                    <StyledTableCell key={cellIndex}>
                                        <Skeleton variant="text" width="100%" />
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        ))
                    ) : (
                        metrics.map((metric) => (
                            <StyledTableRow key={metric.name}>
                                <StyledTableCell component="th" scope="row" isFirstColumn>
                                    {metric.name}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {metric.value1} {metric.unit}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {metric.value2} {metric.unit}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {metric.value} {metric.unit}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))
                    )}
                </TableBody>
            </Table>
        </TableContainer>

    );
}

export default CompareTable;