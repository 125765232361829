import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDashboardContext } from "../../contexts/DashboardContext";
import sizeConfig from "../../configs/sizeConfigs";
import Sidebar from "../Sidebar";
import { useEffect } from "react";
import { getPatientByPatientId, getAllTrialsByPatientId } from "../../utils/fastAPI";

const DashboardLayout = () => {
  const { patientId } = useParams();
  // Patient state
  const { setPatientId, setPatientName, setPatientEmail, setPatientInfo, setIsLoading } = useDashboardContext();
  const { setAllTrials, setLatestTrial, setLatestTrialId } = useDashboardContext();

  useEffect(() => {
    const fetchPatientData = async () => {
      if (patientId) {
        setIsLoading(true);
        const patient = await getPatientByPatientId(patientId);
        if (patient) {
          setPatientId(patientId);
          setPatientInfo(patient);
        }
      }
    }
    const fetchAllTrials = async () => {
      if (patientId) {
        const trials = await getAllTrialsByPatientId(patientId);
        if (trials) {
          const latestTrial = trials[0];
          const latestTrialId = trials[0]?.id;
          setAllTrials(trials);
          setLatestTrial(latestTrial);
          setLatestTrialId(latestTrialId);
          setIsLoading(false);
        }
      }
    }
    fetchPatientData();
    fetchAllTrials();
  }, [patientId]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="nav"
        sx={{
          width: sizeConfig.sidebar.width,
          flexShrink: 0,
        }}
      >
        <Sidebar />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: `calc(100% - ${sizeConfig.sidebar.width})`,
          backgroundColor: "#fafafa ",
          minHeight: "100vh",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardLayout;
