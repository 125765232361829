import axios from 'axios';

const BASE_URL = 'https://evowalkmetrics.com:9000';
const AUTH_URL = 'https://api.evowalk.movilizame.com.ar/auth'

export const login = async (email, password) => {
    try {
        const formData = new FormData();
        formData.append('username', email);
        formData.append('password', password);

        const response = await axios.post(`${AUTH_URL}/login`, formData, {
            headers: { 
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.error('There was an error logging in:', error);
        return { success: false, message: 'Login failed. Please try again later.' };
    }  
};

export const getUserByToken = async (token) => {
    try {
        const response = await axios.get(`${AUTH_URL}/session`, { headers: { Authorization: `Bearer ${token}` } });
        return response.data;                                        
    } catch (error) {
        console.error('There was an error fetching the user data:', error);
        return null;
    }
};

export const getUserByUserId = async (userId) => {
    try {
        const response = await axios.get(`${BASE_URL}/user_info/${userId}`);
        return response.data;
    } catch (error) {
        console.error('There was an error fetching the user data:', error);
        return null;
    }
};

export const getPatientsByClinicianId = async (clinicianId) => {
    try {
        const response = await axios.get(`${BASE_URL}/patients/${clinicianId}`);
        return response.data;
    } catch (error) {
        console.error('There was an error fetching the patients list:', error);
        return null;
    }
};

export const getPatientByPatientId = async (patientId) => {
    try {
        const response = await axios.get(`${BASE_URL}/patient_info/${patientId}`);
        return response.data;
    } catch (error) {
        console.error('There was an error fetching the patient data:', error);
        return null;
    }
};

export const getAllTrialsByPatientId = async (patientId) => {
    try {
        const response = await axios.get(`${BASE_URL}/trials/${patientId}`);
        return response.data;
    } catch (error) {
        console.error('There was an error fetching the trials:', error);
        return null;
    }
}

export const getTrialByTrialId = async (trialId) => {
    try {
        const response = await axios.get(`${BASE_URL}/trial/${trialId}`);
        return response.data;
    } catch (error) {
        console.error('There was an error fetching trial:', error);
        return null;
    }
}

export const getSummaryDataByTrialId = async (trialId) => {
    try {
        const response = await axios.get(`${BASE_URL}/walk_data_summary/${trialId}`);
        return response.data;
    } catch (error) {
        console.error('There was an error fetching summary data:', error);
        return null;
    }
}

export const getRawDataByTrialId = async (trialId) => {
    try {
        const response = await axios.get(`${BASE_URL}/walk_data_raw/${trialId}`);
        return response.data;
    } catch (error) {
        console.error('There was an error fetching raw data:', error);
        return null;
    }
}

const getDateString = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');

    const dateString = `${year}-${month}-${day}`;

    return dateString;
}

export const getDailyDataByWeek = async (patientId, weekStart, weekEnd) => {
    const weekStartStr = getDateString(weekStart);
    const weekEndStr = getDateString(weekEnd);
    try {
        const response = await axios.get(`${BASE_URL}/daily_data_by_week`, {
            params: {
                patient_id: patientId,
                startDate: weekStartStr,
                endDate: weekEndStr
            }
        });
        return response.data;
    } catch(error) {
        console.error('There was an error fetching weekly data:', error);
        return null;
    }
}

export const getDailyDataByMonth = async (patientId, month) => {
    const dateString = getDateString(month);
    try {
        const response = await axios.get(`${BASE_URL}/daily_data_by_month`, {
            params: {
                patient_id: patientId,
                date: dateString
            }
        });
        return response.data;
    } catch(error) {
        console.error('There was an error fetching monthly data:', error);
        return null;
    }
}

export const getDailyDataByYear = async (patientId, year) => {
    const dateString = getDateString(year);
    try {
        const response = await axios.get(`${BASE_URL}/daily_data_by_year`, {
            params: {
                patient_id: patientId,
                date: dateString
            }
        });
        console.log(response.data);
        return response.data;
    } catch(error) {
        console.error('There was an error fetching monthly data:', error);
        return null;
    }
}

export const getDailyDataByRange = async (patientId, startDate, endDate) => {
    const startDateStr = getDateString(startDate);
    const endDateStr = getDateString(endDate);
    try {
        const response = await axios.get(`${BASE_URL}/daily_data_by_range`, {
            params: {
                patient_id: patientId,
                startDate: startDateStr,
                endDate: endDateStr
            }
        });
        return response.data;
    } catch(error) {
        console.error('There was an error fetching range data:', error);
        return null;
    }
}
