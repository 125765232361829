import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TodayIcon from '@mui/icons-material/Today';
import TimerIcon from "@mui/icons-material/Timer";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LegIcon from "@mui/icons-material/Accessibility";
import EventNoteIcon from "@mui/icons-material/EventNote";
import Skeleton from "@mui/material/Skeleton";
import TrialPicker from "./TrialPicker";
import { useState, useEffect } from "react";
import { formatTimestamp, formatTrialDate } from "../../utils/timeUtils";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material";


const InfoGrid = ({ selectedTrialInfo, setCurrentTrialId, currentTrialId, loading, summaryData }) => {
  const [selectedDate, setSelectedDate] = useState(() => {
    return formatTrialDate(selectedTrialInfo?.date);
  });

  useEffect(() => {
    setSelectedDate(formatTrialDate(selectedTrialInfo?.date));
  }, [selectedTrialInfo]);


  const spacingValue = 1.5;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#BBDDE0",
      color: theme.palette.common.black,
      fontSize: 17,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 17,
    },
    height: 58,
    border: 'none',
    // borderRight: '1px solid rgba(224, 224, 224, 1)' // Add a right border to each cell
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: "#F4F5F7",
      borderBottom: "none",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: "#F4F5F7",
      borderBottom: "none",
    },
  }));

  return (
    <Grid container spacing={spacingValue}>
      {/* left */}
      < Grid item lg={2.5}>
        <TrialPicker
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setCurrentTrialId={setCurrentTrialId}
          currentTrialId={currentTrialId}
        />
      </Grid >
      <Grid item lg={9.5}>
        <Box width="100%">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <TodayIcon />
                      <Box width={8} /><span>Trial Date</span>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <TimerIcon /><Box width={8} /><span>Time Period</span>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <LegIcon /><Box width={8} /><span>Leg Side</span>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <DescriptionOutlinedIcon /><Box width={8} /><span>Session Type</span>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <EventNoteIcon /><Box width={8} /><span>Note</span>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  [...Array(1)].map((_, index) => (
                    <StyledTableRow key={index}>
                      {[...Array(5)].map((_, cellIndex) => (
                        <StyledTableCell key={cellIndex} align="center">
                          <Skeleton variant="text" />
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <StyledTableCell align="center">
                      {/* {selectedTrialInfo?.date === "" || selectedTrialInfo?.date === null
                        ? "N/A"
                        : selectedTrialInfo.date} */}
                      {selectedTrialInfo?.date}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {/* {selectedTrialInfo?.date === "" || selectedTrialInfo?.date === null
                        ? "N/A"
                        : formatTimestamp(selectedTrialInfo.startTimestamp) +
                        "-" +
                        formatTimestamp(selectedTrialInfo.endTimestamp)} */}
                      {/* {
                        selectedTrialInfo?.startTimestamp === "" || selectedTrialInfo?.startTimestamp === null
                          ? "N/A"
                          : selectedTrialInfo.startTimestamp} */}
                      {selectedTrialInfo?.timePeriod}
                    </StyledTableCell>
                    <StyledTableCell align="center">{summaryData?.deviceLocation}</StyledTableCell>
                    <StyledTableCell align="center">{selectedTrialInfo?.sessionType}</StyledTableCell>
                    <StyledTableCell align="center">{selectedTrialInfo?.notes == "" ? selectedTrialInfo?.clinician_notes : selectedTrialInfo?.notes}</StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid >
  );
};

export default InfoGrid;
