import Box from "@mui/material/Box";
import MultiSelector from "../../components/MultiSelector";
import KneeFlexChart from "./KneeFlexChart";
import HipFlexChart from "./HipFlexChart";
import AnkleSpeedChart from "./AnkleSpeedChart";
import IsWalkChart from "./IsWalkChart";
import FootDragChart from "./FootDragChart";
import GaitCycleChart from "./GaitCycleChart";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import LinearProgress from '@mui/material/LinearProgress';
import { useDashboardContext } from "../../contexts/DashboardContext";
import { useEffect, useState, useRef } from "react";
import { getRawDataByTrialId } from '../../utils/fastAPI'

const Metrics = ({ trialId1, trialId2, setPdfCharts, selectedMetrics, setSelectedMetrics }) => {
    const { latestTrialId } = useDashboardContext();
    const [rawData1, setRawData1] = useState([]);
    const [rawData2, setRawData2] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!latestTrialId) {
            return;
        }

        async function fetchRawData1() {
            setIsLoading(true);
            const rawData1 = await getRawDataByTrialId(trialId1);
            setRawData1(rawData1);
            setIsLoading(false);
        }
        fetchRawData1();
    }, [trialId1]);

    useEffect(() => {
        if (!latestTrialId) {
            return;
        }

        async function fetchRawData2() {
            setIsLoading(true);
            const rawData2 = await getRawDataByTrialId(trialId2);
            setRawData2(rawData2);
            setIsLoading(false);
        }
        fetchRawData2();
    }, [trialId2]);

    useEffect(() => {
        setTimeout(() => {
            captureCharts();
        }, 3000);
    }, [trialId1, trialId2, selectedMetrics]);

    const kneeFlexRef1 = useRef();
    const hipFlexRef1 = useRef();
    const ankleSpeedRef1 = useRef();
    const isWalkRef1 = useRef();
    const footDragRef1 = useRef();
    const gaitCycleRef1 = useRef();

    const kneeFlexRef2 = useRef();
    const hipFlexRef2 = useRef();
    const ankleSpeedRef2 = useRef();
    const isWalkRef2 = useRef();
    const footDragRef2 = useRef();
    const gaitCycleRef2 = useRef();

    const captureChart = (chartRef) => {
        if (!latestTrialId) {
            return;
        }

        if (chartRef.current) {
            const echartsInstance = chartRef.current.getEchartsInstance();
            echartsInstance.setOption({
                xAxis: { name: false },
            });

            // Capture the chart
            const url = echartsInstance.getDataURL({
                pixelRatio: 2,
                backgroundColor: '#fff',
                excludeComponents: ['toolbox', 'dataZoom', 'title', 'legend', 'tooltip',],
            });

            echartsInstance.setOption({
                xAxis: { name: true },
            });

            return url;
        }
    };

    const captureCharts = async () => {
        if (!latestTrialId) {
            return;
        }

        const images = [];

        // Define the order of charts and their references
        const chartsInOrder = [
            { ref1: gaitCycleRef1, ref2: gaitCycleRef2, title: "Gait Cycle Chart" },
            { ref1: isWalkRef1, ref2: isWalkRef2, title: "Is Walk Chart" },
            { ref1: kneeFlexRef1, ref2: kneeFlexRef2, title: "Knee Flexion Chart" },
            { ref1: hipFlexRef1, ref2: hipFlexRef2, title: "Hip Flexion Chart" },
            { ref1: footDragRef1, ref2: footDragRef2, title: "Foot Drag Chart" },
            { ref1: ankleSpeedRef1, ref2: ankleSpeedRef2, title: "Ankle Speed Chart" },
        ];

        // Capture charts in the predefined order
        for (let chart of chartsInOrder) {
            if (chart.ref1.current && chart.ref2.current) {
                const chartImage1 = captureChart(chart.ref1);
                const chartImage2 = captureChart(chart.ref2);
                if (chartImage1 && chartImage2) {
                    images.push({ src1: chartImage1, src2: chartImage2, title: chart.title });
                }
            }
        }

        setPdfCharts(images);
    };



    return (
        <Box mb={1}>
            {/* MultiSelector */}
            <Box bgcolor="#F5F5F5" borderRadius={1} mb={2} height="70px">
                <MultiSelector
                    selectedMetrics={selectedMetrics}
                    onChange={setSelectedMetrics}
                />
            </Box>
            {isLoading && (
                <Box width="100%" mt={10}>
                    <LinearProgress />
                </Box>
            )}
            {!isLoading && (
                <Stack spacing={3} alignItems="center">
                    {selectedMetrics.includes("Gait Cycle") && (
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <GaitCycleChart rawData={rawData1} ref={gaitCycleRef1} />
                            </Grid>
                            <Grid item xs={6}>
                                <GaitCycleChart rawData={rawData2} ref={gaitCycleRef2} />
                            </Grid>
                        </Grid>
                    )}
                    {selectedMetrics.includes("Knee Flexion") && (
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <KneeFlexChart rawData={rawData1} ref={kneeFlexRef1} />
                            </Grid>
                            <Grid item xs={6}>
                                <KneeFlexChart rawData={rawData2} ref={kneeFlexRef2} />
                            </Grid>
                        </Grid>)}
                    {selectedMetrics.includes("Hip Flexion") && (
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <HipFlexChart rawData={rawData1} ref={hipFlexRef1} />
                            </Grid>
                            <Grid item xs={6}>
                                <HipFlexChart rawData={rawData2} ref={hipFlexRef2} />
                            </Grid>
                        </Grid>
                    )}
                    {selectedMetrics.includes("Walking Probability") && (
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <IsWalkChart rawData={rawData1} ref={isWalkRef1} />
                            </Grid>
                            <Grid item xs={6}>
                                <IsWalkChart rawData={rawData2} ref={isWalkRef2} />
                            </Grid>
                        </Grid>
                    )}
                    {selectedMetrics.includes("Foot Drag Probability") && (
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <FootDragChart rawData={rawData1} ref={footDragRef1} />
                            </Grid>
                            <Grid item xs={6}>
                                <FootDragChart rawData={rawData2} ref={footDragRef2} />
                            </Grid>
                        </Grid>
                    )}
                    {selectedMetrics.includes("Ankle Speed") && (
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <AnkleSpeedChart rawData={rawData1} ref={ankleSpeedRef1} />
                            </Grid>
                            <Grid item xs={6}>
                                <AnkleSpeedChart rawData={rawData2} ref={ankleSpeedRef2} />
                            </Grid>
                        </Grid>
                    )}
                </Stack>
            )}
        </Box>
    );
};

export default Metrics;
