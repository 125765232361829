import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CircularProgress from '@mui/material/CircularProgress';
import CustomizedButton from "../../components/CustomizedButton";
import { useEffect, useState } from "react";
import Graphs from "./Graphs";
import SummaryMetricsChart from "./SummaryMetricsChart";
import InfoGrid from "./InfoGrid";
import SelectAllIcon from '@mui/icons-material/SelectAll';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useDashboardContext } from "../../contexts/DashboardContext";
import OneTapButton from "./OneTapButton";
import { Button, Modal } from "@mui/material";
import { Modal as BaseModal } from '@mui/base/Modal';
import TextField from '@mui/material/TextField';
import CustomizeSelector from "./CustomizeSelector";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PDFDocument from "./PDFDocument";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { analyzeData } from "../../utils/openaiRequest";
import Chip from '@mui/material/Chip';
import { getTrialByTrialId, getSummaryDataByTrialId } from '../../utils/fastAPI'
import AIAnalysisBox from './AIAnalysisBox';

const AnalyzeTrial = () => {
  const { latestTrial, latestTrialId, patientName, patientInfo } = useDashboardContext();
  const { trialId, patientId } = useParams();
  const navigate = useNavigate();
  const [selectedTrialInfo, setSelectedTrialInfo] = useState("");
  const [currentTrialId, setCurrentTrialId] = useState(trialId);
  const [selectedMetrics, setSelectedMetrics] = useState(["Gait Cycle", "Knee Flexion", "Hip Flexion", "Foot Drag Probability", "Ankle Speed", "Walking Probability"]);
  const [isAIAnalysis, setIsAIAnalysis] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (!latestTrialId) {
      return;
    }
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [pdfCharts, setPdfCharts] = useState([]);
  const [aiAnalysisResult, setAiAnalysisResult] = useState('');
  const [loading, setLoading] = useState(false);
  const [trialInfoLoading, setTrialInfoLoading] = useState(false);
  const [error, setError] = useState(null);
  const [summaryData, setSummaryData] = useState({});

  // useEffect(() => {
  //   if (trialId) {
  //     setCurrentTrialId(trialId);
  //   } else if (latestTrialId) {
  //     setCurrentTrialId(latestTrialId);
  //   }
  //   navigate(`/dashboard/${patientId}/analyze/${currentTrialId}`);
  // }, [trialId, latestTrialId]);

  useEffect(() => {
    const fetchTrialData = async () => {
      setTrialInfoLoading(true);
      try {
        const trialData = await getTrialByTrialId(currentTrialId);
        setSelectedTrialInfo(trialData);
      } catch (err) {
        console.error(err);
      } finally {
        setTrialInfoLoading(false);
      }
    };

    const fetchSummaryData = async () => {
      setTrialInfoLoading(true);
      try {
        const summaryData = await getSummaryDataByTrialId(currentTrialId);
        setSummaryData(summaryData);
      } catch (err) {
        console.error(err);
      } finally {
        setTrialInfoLoading(false);
      }
    };

  

    navigate(`/dashboard/${patientId}/analyze/${currentTrialId}`);
    fetchTrialData();
    fetchSummaryData();
  }, [currentTrialId]);

  // useEffect(() => {
  //   const fetchTrialData = async (currentTrialId) => {
  //     setTrialInfoLoading(true);
  //     try {
  //       const trialData = await getTrialByTrialId(currentTrialId);
  //       setSelectedTrialInfo(trialData);
  //     } catch (err) {
  //       console.error(err);
  //     } finally {
  //       setTrialInfoLoading(false);
  //     }
  //   };

  //   const fetchSummaryData = async (currentTrialId) => {
  //     setTrialInfoLoading(true);
  //     try {
  //       const summaryData = await getSummaryDataByTrialId(currentTrialId);
  //       setSummaryData(summaryData);
  //     } catch (err) {
  //       console.error(err);
  //     } finally {
  //       setTrialInfoLoading(false);
  //     }
  //   };


  //   navigate(`/dashboard/${patientId}/analyze/${currentTrialId}`);
  //   fetchTrialData(currentTrialId);
  //   fetchSummaryData(currentTrialId);
  // }, [currentTrialId]);

  // useEffect(() => {
  //   if (!latestTrialId) {
  //     return;
  //   }

  //   const fetchTrialData = async () => {
  //     if (currentTrialId) {
  //       setTrialInfoLoading(true);
  //       const trailData = await getTrialByTrialId(currentTrialId);
  //       setSelectedTrialInfo(trailData);
  //       setTrialInfoLoading(false);
  //     }
  //   }

  //   const fetchSummaryData = async () => {
  //     if (currentTrialId) {
  //       setTrialInfoLoading(true);
  //       const summaryData = await getSummaryDataByTrialId(currentTrialId);
  //       setSummaryData(summaryData);
  //       setTrialInfoLoading(false);
  //     }
  //   }

  //   if (currentTrialId) {
  //     // navigate(`/dashboard/${patientId}/analyze/${currentTrialId}`);
  //     fetchTrialData();
  //     fetchSummaryData();
  //   } else {
  //     setSelectedTrialInfo(latestTrial);
  //     setCurrentTrialId(latestTrialId);
  //   }
  // }, [currentTrialId, latestTrialId]);

  const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    color: '#5a5a5a',
    display: 'inline-block',
    marginRight: theme.spacing(2),
  }));

  const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    border: '1px solid #F5F5F5',
    boxShadow: 24,
    p: 3,
    borderRadius: 2,
  };

  const handleSetLatestTrial = () => {
    setCurrentTrialId(latestTrialId);
  }

  const handleSetAllMetrics = () => {
    setSelectedMetrics(["Gait Cycle", "Knee Flexion", "Hip Flexion", "Walking Probability", "Foot Drag Probability", "Ankle Speed"]);
  }

  const handleClearAllMetrics = () => {
    setSelectedMetrics([]);
  }

  const handleAIAnalysis = async () => {
    setIsAIAnalysis(true);
    setAiAnalysisResult("");
    setLoading(true);
    setError(null);
    try {
      const result = await analyzeData(summaryData, patientInfo.conditionDescription);
      setAiAnalysisResult(result);
    } catch (err) {
      setError('Failed to analyze data.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const generateDefaultReportName = (patientName) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `Report_${patientName}_${year}_${month}_${day}`;
  }

  // Report Name
  const [reportName, setReportName] = useState(() => {
    return generateDefaultReportName(patientName);
  });
  const handleInputChange = (event) => {
    setReportName(event.target.value);
  };

  const [selectedReportSections, setSelectedReportSections] = useState(["Graph", "Summary Metrics"]);
  const namesForReportSections = [
    "Graph",
    "Summary Metrics",
  ];

  const namesForReportMetrics = [
    "Gait Cycle",
    "Walking Probability",
    "Knee Flexion",
    "Hip Flexion",
    "Foot Drag Probability",
    "Ankle Speed",
  ];

  const handleDecimals = (value) => {
    if (Number.isInteger(value)) {
      return value;
    } else {
      return parseFloat(value).toFixed(1);
    }
  };

  const handleReportTableContent = () => {
    if (summaryData) {
      return {
        "Steps": handleDecimals(summaryData.peak_step_count) + " steps",
        "Walking Time": handleDecimals(summaryData.walking_time) + " s",
        "Speed": handleDecimals(summaryData.speed) + " m/s",
        "Distance": handleDecimals(summaryData.distance) + " m",
        "Cadence": handleDecimals(summaryData.cadence) + " / min",
        "Stride Length": handleDecimals(summaryData.step_length) + " m",
        "Swing %": handleDecimals(summaryData.swingRatio) + " %",
        "Stance %": handleDecimals(summaryData.stanceRatio) + " %",
        "Knee Flex° Mid Stance": handleDecimals(summaryData.midstanceKnee) + " °",
        "Knee Flex° Initial Swing": handleDecimals(summaryData.initialswingKnee) + " °",
        "Knee Flex° Mid Swing": handleDecimals(summaryData.midswingKnee) + " °",
        "Total Knee ROM°": handleDecimals(summaryData.romKnee) + " °",
      };
    }
  }

  return (
    <Box display="flex" flexDirection="column" m={1}>
      {/* <StyledTypography variant="h6">One Tap Functions</StyledTypography> */}
      <Box display="flex">
        <OneTapButton title="Select latest clinical trial" subtitle="Reset trial to latest trial" onClick={handleSetLatestTrial} />
        <OneTapButton title="Analyze my trial" subtitle="Trial Analysis based on walking metrics" onClick={handleAIAnalysis} />
        <OneTapButton title="Generate report" subtitle="Create a report for this trial" onClick={handleOpen} />
      </Box>
      <Box display="flex">
        <Modal
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={open}
        >
          <Box sx={{ ...ModalStyle, position: 'relative' }} display="flex" flexDirection="column" gap={2}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <StyledTypography variant="h7">choose sections:</StyledTypography>
            <Box bgcolor="#F5F5F5" borderRadius={1} width="100%">
              <CustomizeSelector selectedMetrics={selectedReportSections} onChange={setSelectedReportSections} names={namesForReportSections} />
            </Box>
            <StyledTypography variant="h7">choose metrics:</StyledTypography>
            <Box bgcolor="#F5F5F5" borderRadius={1} width="100%">
              <CustomizeSelector selectedMetrics={selectedMetrics} onChange={setSelectedMetrics} names={namesForReportMetrics} />
            </Box>
            <StyledTypography variant="h7">rename report:</StyledTypography>
            <Box bgcolor="#F5F5F5" borderRadius={1} width="100%">
              <TextField
                id="outlined-basic"
                value={reportName}
                onChange={handleInputChange}
                fullWidth
                borderRadius={2}
                aria-label="none"
                InputProps={{
                  disableUnderline: true,
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                    },
                    '& fieldset': { border: 'none' },
                  },
                }}
              />
            </Box>
            <Box display="flex" gap={2}>
              <Button variant="outlined">
                <PDFDownloadLink document={<PDFDocument trialInfo={selectedTrialInfo} tableContent={handleReportTableContent(summaryData)} summaryData={summaryData} patientInfo={patientInfo} pdfCharts={pdfCharts} />} fileName={`${reportName}.pdf`}>
                  {({ blob, url, loading, error }) => (loading ? 'Generating document...' : 'Download document')}
                </PDFDownloadLink>
              </Button>
              <Button variant="outlined" onClick={handleClose}>Cancel</Button>
            </Box>
          </Box>
        </Modal>
      </Box>
      <AIAnalysisBox 
        isAIAnalysis={isAIAnalysis}
        setIsAIAnalysis={setIsAIAnalysis}
        aiAnalysisResult={aiAnalysisResult}
        loading={loading}
        error={error}
      />
      <Box display="flex" mb={1}>
        <StyledTypography variant="h6" >Select Trial</StyledTypography>
        {currentTrialId === latestTrialId && <Chip label="LATEST TRIAL" sx={{ fontWeight: 'bold' }} />}
      </Box>
      {/* Trial Info */}
      <Box display="flex" flexDirection="column" mb={2} gap={2}>
        <InfoGrid
          selectedTrialInfo={selectedTrialInfo}
          setCurrentTrialId={setCurrentTrialId}
          currentTrialId={currentTrialId}
          loading={trialInfoLoading}
          summaryData={summaryData}
        />
        <SummaryMetricsChart summaryData={summaryData} loading={trialInfoLoading} />
      </Box>
      <Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <StyledTypography variant="h6">
            Select Metrics
          </StyledTypography>
          <Box display="flex" gap={2}>
            <Button
              onClick={handleSetAllMetrics}
              sx={{
                backgroundColor: '#F5F5F5', // light grey
                border: '2px solid #C0C0C0', // silver
                color: '#000000', // dark grey
                fontSize: '14px',
                transition: 'background-color 0.3s',
                '&:hover': {
                  backgroundColor: '#E0E0E0', // slightly darker grey on hover
                },
              }}
            >
              <SelectAllIcon fontSize="small" />
              <span style={{ marginLeft: '5px' }}>SELECT ALL</span>
            </Button>
            <Button
              onClick={handleClearAllMetrics}
              sx={{
                backgroundColor: '#F5F5F5', // light grey
                border: '2px solid #C0C0C0', // silver
                color: '#000000', // dark grey
                fontSize: '14px',
                transition: 'background-color 0.3s',
                '&:hover': {
                  backgroundColor: '#E0E0E0', // slightly darker grey on hover
                },
              }}
            >
              <ClearAllIcon fontSize="small" />
              <span style={{ marginLeft: '5px' }}>CLEAR ALL</span>
            </Button>
          </Box>
        </Box>
        <Graphs currentTrialId={currentTrialId} selectedMetrics={selectedMetrics} setSelectedMetrics={setSelectedMetrics} setPdfCharts={setPdfCharts} />
      </Box>
    </Box>
  );
};

export default AnalyzeTrial;
