import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomizedButton from '../../components/CustomizedButton';
import Divider from '@mui/material/Divider';
import CustomizedTable from '../../components/CustomizedTable';
import TrialPicker from './TrialPicker';
import colorConfigs from '../../configs/colorConfigs';
import Stack from '@mui/material/Stack';
import StatusIndicator from './StatusIndicator';
import CompareSwitchButton from './CompareSwitchButton';
import MultiSelector from './MultiSelector';
import TextField from '@mui/material/TextField';
import WarningIcon from '@mui/icons-material/Warning';
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDashboardContext } from "../../contexts/DashboardContext";
import { styled } from '@mui/material/styles';
import { formatTrialDate } from '../../utils/timeUtils';
import { fetchTrialByTrialId } from '../../utils/dataFetcher';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDocument from './PDFDocument';
import { fetchRawDataByTrialId } from '../../utils/dataFetcher';
import html2canvas from 'html2canvas';
import KneeFlexChart from './KneeFlexChart';
import { useRef } from 'react';

const Reports = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { patientId, trialId } = params;
    const { latestTrial, patientName, patientInfo } = useDashboardContext();
    const [currentTrialId, setCurrentTrialId] = useState(trialId);
    const [selectedTrialInfo, setSelectedTrialInfo] = useState(latestTrial);

    const captureChartAsImage = async (chartRef) => {
        const canvas = await html2canvas(chartRef.current);
        return canvas.toDataURL();
    }

    const chartRef = useRef();
    const [chartImageData, setChartImageData] = useState(null);
    const [isImageCaptured, setIsImageCaptured] = useState(false);
    useEffect(() => {
        const capture = async () => {
            if (chartRef.current) {
                const capturedImage = await captureChartAsImage(chartRef);
                setChartImageData(capturedImage);
                setIsImageCaptured(true); // Image has been captured
            }
        };

        // A slight delay to ensure the chart is fully rendered
        setTimeout(() => {
            capture();
        }, 3000); // 1 second delay, adjust as needed
    }, []);

    const offScreenStyle = {
        position: 'absolute',
        left: '-10000px',
        top: '-10000px',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        pointerEvents: 'none', // Ensure it doesn't interfere with user interactions
    };

    useEffect(() => {
        const fetchTrialData = async () => {
            if (currentTrialId) {
                const trialData = await fetchTrialByTrialId(currentTrialId);
                setSelectedTrialInfo(trialData);
            }
        };

        navigate(`/dashboard/${patientId}/reports/${currentTrialId}`);

        fetchTrialData();
    }, [currentTrialId]);

    const [seletedDate, setSelectedDate] = useState(() => {
        return formatTrialDate(selectedTrialInfo?.date);
    });

    const [isCompare, setIsCompare] = useState(false);

    // Select Sections
    const [selectedSections, setSelectedSections] = useState(["Graph", "Summary Metrics"]);
    const namesForSections = [
        "Graph",
        "Summary Metrics",
    ];

    // Select Metrics
    const [selectedMetrics, setSelectedMetrics] = useState(["Knee_Flex", "Hip_Flex", "Walk_Probability", "Percentage_Gait_Cycle"]);
    const namesForMetrics = [
        "Knee_Flex",
        "Hip_Flex",
        "Ankle_Speed",
        "Walk_Probability",
        "Foot_Drag",
        "Percentage_Gait_Cycle",
    ];

    const generateDefaultReportName = (patientName) => {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        return `Report_${patientName}_${year}_${month}_${day}`;
    }

    // Report Name
    const [reportName, setReportName] = useState(() => {
        return generateDefaultReportName(patientName);
    });
    const handleInputChange = (event) => {
        setReportName(event.target.value);
    };

    // Report History Table
    const headersForMetrics = ["Date of Report"];
    const dataForMetrics = [
        ["report2_patientA_2021-10-10"],
        ["report1_patientA_2021-10-10"],
        ["report1_patientB_2021-10-10"],
    ];

    // Raw Data for Graphs
    const [rawData, setRawData] = useState([]);
    useEffect(() => {
        const fetchRawData = async () => {
            const data = await fetchRawDataByTrialId(currentTrialId);
            setRawData(data);
        }
        fetchRawData();
    }, [currentTrialId]);

    // useEffect(() => {
    //     fetchRawData();
    // }, [selectedTrialInfo.docId]);

    const StyledTypography = styled(Typography)(({ theme }) => ({
        fontWeight: 600,
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
        color: '#5a5a5a',
        display: 'inline-block',
        marginRight: theme.spacing(2),
    }));

    return (
        <Box display="flex" flexDirection="column" m={1}>
            {/* Select Trials */}
            <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" spacing={2} mb={2}>
                <Stack direction="row" spacing={1}>
                    <StyledTypography variant="h6">Select Trials:</StyledTypography>
                    {/* <StatusIndicator isReady={true} /> */}
                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" border={2} borderRadius={2} p={1} >
                        <WarningIcon sx={{ color: "red" }} />
                        <Typography variant="h6" color="dark">This page is under maintenance</Typography>
                    </Box>
                </Stack>
                <Box pl={3}>
                    <TrialPicker currentTrialId={currentTrialId} setCurrentTrialId={setCurrentTrialId} selectedDate={seletedDate} setSelectedDate={setSelectedDate} />
                </Box>
            </Stack>
            {/* Customize Report */}
            <Stack display="flex" flexDirection="column">
                {/* Subtitle Section */}
                <Stack direction="row" spacing={2} alignItems="center">
                    <StyledTypography variant="h6">Customize Report</StyledTypography>
                    {/* <CompareSwitchButton isCompare={isCompare} setIsCompare={setIsCompare} /> */}
                </Stack>
                {/* Choose Section */}
                <Stack direction="column" gap={2} pl={3} pr={3} pb={2}>
                    <Stack direction="column" alignItems="flex-start" spacing={1}>
                        <StyledTypography variant="h7">choose sections:</StyledTypography>
                        <Box bgcolor={colorConfigs.tertiary} borderRadius={1} width="100%">
                            <MultiSelector selectedMetrics={selectedSections} onChange={setSelectedSections} names={namesForSections} />
                        </Box>
                    </Stack>
                    <Stack direction="column" alignItems="flex-start" spacing={1}>
                        <StyledTypography variant="h7">choose metrics:</StyledTypography>
                        <Box bgcolor={colorConfigs.tertiary} borderRadius={1} width="100%">
                            <MultiSelector selectedMetrics={selectedMetrics} onChange={setSelectedMetrics} names={namesForMetrics} />
                        </Box>
                    </Stack>
                    <Stack direction="column" alignItems="flex-start" spacing={1}>
                        <StyledTypography variant="h7">rename report:</StyledTypography>
                        <Box bgcolor={colorConfigs.tertiary} borderRadius={1} width="100%">
                            <TextField
                                id="outlined-multiline-static"
                                value={reportName}
                                onChange={handleInputChange}
                                fullWidth
                                borderRadius={2}
                                aria-label='none'
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'transparent',
                                        },
                                        '& fieldset': { border: 'none' },
                                    },
                                }}
                            />
                        </Box>
                    </Stack>
                    {/* Compare Section */}
                    {/* {isCompare && (
                        <Stack direction="column" alignItems="flex-start" spacing={2}>
                            <StyledTypography variant="h7">compare with:</StyledTypography>
                            <TrialPicker selectedDate={seletedDate} setSelectedDate={setSelectedDate} setSelectedTrialInfo={setSelectedTrialInfo} />
                        </Stack>
                    )} */}
                </Stack>
            </Stack>
            <Box display="flex" flexDirection="row" justifyContent="flex-start" mt={2}>
                {isImageCaptured && (
                    <PDFDownloadLink
                        document={<PDFDocument trialInfo={selectedTrialInfo} rawData={rawData} patientInfo={patientInfo} chartImageData={chartImageData} />}
                        fileName={`${reportName}.pdf`}
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : 'Download Report'
                        }
                    </PDFDownloadLink>
                )}
            </Box>
            <div ref={chartRef} style={offScreenStyle}>
                <KneeFlexChart rawData={rawData} />
            </div>
            {/* <Box display="flex" flexDirection="row" justifyContent="flex-start" mt={2}>
                <CustomizedButton>Download Report</CustomizedButton>
            </Box> */}
            {/* Generate Report */}
            {/* <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                mb={3}
            >
                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    <Typography>generate report in</Typography>
                </Box>
                <CustomizedButton variant="text">PDF</CustomizedButton>
                <Divider orientation="vertical" variant="middle" flexItem />
                <CustomizedButton variant="text">CSV</CustomizedButton>
            </Box> */}
            {/* <TemplateToPDF rawData={rawData} selectedMetrics={selectedMetrics} /> */}
            {/* Report History Titlte */}
            {/* <Box width="100%" mb={1}>
                <StyledTypography variant="h6" mb={1}>Report History</StyledTypography>
            </Box> */}
            {/* Report History Table */}
            {/* <Box display="flex" justifyContent="center">
                <Box width="95%">
                    <CustomizedTable headers={headersForMetrics} data={dataForMetrics} includeButton={true} butttonText="download as PDF | CSV" />
                </Box>
            </Box> */}
        </Box>
    )
};

export default Reports;