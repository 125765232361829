import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import { doc, getDoc, collection, query, where, orderBy, limit, getDocs } from "firebase/firestore";
import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';
import Header from "./Header";
import CustomizedButton from "../../components/CustomizedButton";
import CustomizedSearchBar from "../../components/CustomizedSearchBar";
import UserInfo from "../../components/UserInfo";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PatientsTable from "./PatientsTable";
import { useDashboardContext } from "../../contexts/DashboardContext";
import { getUserByUserId, getPatientsByClinicianId } from "../../utils/fastAPI";

const Patients = () => {
  // Define headers for the table
  const headers = ["Patient Name", "Email", "Condition", "Last Date Used"];
  const { setUserName, setUserEmail } = useDashboardContext();
  const [patients, setPatients] = useState([]); // Holds the patient data
  const [isDataFetched, setIsDataFetched] = useState(false); // Indicates if data fetching is complete
  const [searchTerm, setSearchTerm] = useState(""); // Holds the search term
  const [sortOrder, setSortOrder] = useState('desc'); // Holds the sort order
  // const userUid = auth.currentUser && auth.currentUser.uid; // Get the current user's UID
  const userId = localStorage.getItem('userId');
  const clinicianId = localStorage.getItem('clinicianId');
  const [sortField, setSortField] = useState('date'); // 'date' or 'name'

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUserByUserId(userId);
      setUserName(user?.name);
      setUserEmail(user?.email);
    }
    if (userId) {
      fetchUser();
    }
  }, [userId]);

  useEffect(() => {
    const fetchPatients = async () => {
      const patients = await getPatientsByClinicianId(clinicianId);
      setPatients(patients);
      setIsDataFetched(true);
    }
    if (clinicianId) {
      fetchPatients();
    }
  }, [clinicianId]);

  useEffect(() => {
    const fetchPatients = async () => {
      const patients = await getPatientsByClinicianId(clinicianId);
      const sortedPatients = sortPatientsRefresh(patients); // Sort patients by default
      setPatients(sortedPatients);
      setIsDataFetched(true);
    }
    if (clinicianId) {
      fetchPatients();
    }
  }, [clinicianId]);

  // useEffect(() => {
  //   const fetchPatients = async () => {
  //     // Retrieve the user document from Firestore
  //     const userDoc = doc(db, "users", userUid);
  //     const userDocSnap = await getDoc(userDoc);

  //     if (userDocSnap.exists()) {
  //       // User name and user email
  //       const userDate = userDocSnap.data();
  //       const patientIds = userDate.patientIds || [];
  //       setUserName(userDate.name);
  //       setUserEmail(userDate.email);

  //       // Fetch data for each patient from patients document
  //       const patientData = await Promise.all(
  //         patientIds.map(async (patientId) => {
  //           // console.log("Fetching patient data for", patientId);
  //           const patientDoc = doc(db, "patients", patientId);
  //           const patientDocSnap = await getDoc(patientDoc);

  //           // Query the latest data for the patient from minute_data collection
  //           const minuteDataQuery = query(collection(db, 'minute_data'), where('patientId', '==', patientId), orderBy('date', 'desc'), limit(1));
  //           const minuteDataSnapshot = await getDocs(minuteDataQuery);

  //           let latestDate = null;
  //           if (!minuteDataSnapshot.empty) {
  //             latestDate = minuteDataSnapshot.docs[0].data().date;
  //           }

  //           if (patientDocSnap.exists()) {
  //             // Extract the relevant patient information
  //             return {
  //               id: patientId,
  //               patientName: patientDocSnap.data().name,
  //               email: patientDocSnap.data().email,
  //               condition: patientDocSnap.data().medicalCondition,
  //               lastDateUsed: latestDate,
  //             };
  //           } else {
  //             console.log("No such patient document!", patientId);
  //             return null;
  //           }
  //         })
  //       );

  //       // Set the fetched patient data, remove null values, and indicate data fetching is complete
  //       setPatients(sortPatients(patientData).filter(Boolean));
  //       setIsDataFetched(true);
  //     } else {
  //       console.log("No such user document!");
  //     }
  //   };

  //   fetchPatients();
  // }, [userUid]);

  const sortPatients = (patientsData) => {
    return patientsData.sort((a, b) => {
      if (sortField === 'name') {
        // Sorting by name
        return sortOrder === 'desc'
          ? b.patientName.localeCompare(a.patientName)
          : a.patientName.localeCompare(b.patientName);
      } else {
        // Sorting by date (existing logic)
        const dateA = a.lastDateUsed ? new Date(a.lastDateUsed) : new Date(0);
        const dateB = b.lastDateUsed ? new Date(b.lastDateUsed) : new Date(0);
        return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
      }
    });
  };

  const sortPatientsRefresh = (patientsData) => {
    return patientsData.sort((a, b) => {
      const dateA = a.lastDateUsed ? new Date(a.lastDateUsed) : new Date(0);
      const dateB = b.lastDateUsed ? new Date(b.lastDateUsed) : new Date(0);
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });
  };

  const toggleSort = (field) => {
    if (field === sortField) {
      // Toggle sort order if same field
      setSortOrder(prevSortOrder => prevSortOrder === 'desc' ? 'asc' : 'desc');
    } else {
      // Change sort field and set default order
      setSortField(field);
      setSortOrder('desc');
    }
  };

  useEffect(() => {
    setPatients(prevPatients => sortPatients([...prevPatients]));
  }, [sortOrder, sortField]);

  const filteredPatients = patients.filter((patient) =>
    Object.values(patient).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const dataToDisplay = searchTerm ? filteredPatients : patients;


  return (
    <Box>
      {/* Header component */}
      <Header />
      {/* Searchbar component */}
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="center" width="100%" mb={3}>
          <Box width="95%">
            <CustomizedSearchBar
              placeholderText="Search by name/email/condition"
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </Box>
        </Box>
        {/* Sort Button */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mb={1}
          width="100%"
        >
          <Box width="95%" display="flex" justifyContent="flex-end">
            {/* Render the CustomizedButton component */}
            <CustomizedButton onClick={() => toggleSort('date')} variant="text">
              {sortField === 'date' && (sortOrder === 'desc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
              Sort by Date
            </CustomizedButton>
            <CustomizedButton onClick={() => toggleSort('name')} variant="text">
              {sortField === 'name' && (sortOrder === 'desc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
              Sort by Name
            </CustomizedButton>
          </Box>
        </Box>
        {/* PatientsTable */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          mb={10}
        >
          {!isDataFetched ? (
            <Box width="95%" mt={15}>
              <LinearProgress />
            </Box>
          ) : (
            <Box width="95%">
              <PatientsTable
                data={dataToDisplay}
              />
            </Box>
          )}
        </Box>
        {/* User Info on the left-bottom corner */}
        <Box position="fixed" bottom={0} left={0} width="25%" p={2}>
          <UserInfo />
        </Box>
      </Box>
    </Box>
  );
}

export default Patients;