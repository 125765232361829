import React from 'react';
import { AppBar, Toolbar, Divider, Box } from '@mui/material';
import CustomizedButton from '../../components/CustomizedButton';
import assets from '../../assets';
import { useDashboardContext } from '../../contexts/DashboardContext';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const { resetDashboard } = useDashboardContext();
    const navigate = useNavigate();

    const handleLogout = () => {
        try {
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('clinicianId');
            localStorage.removeItem('isLoggedIn');
            resetDashboard();
            navigate('/');
            console.log("Logged out successfully!");
        } catch (error) {
            console.error('There was an error logging out:', error);
        }
    };

    return (
        <div style={{ marginBottom: '60px' }}>
            <AppBar position="static" sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
                <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CustomizedButton variant="text" onClick={handleLogout}>Logout</CustomizedButton>
                    <img src={assets.images.logo} alt="logo" style={{ width: '200px' }} />
                </Toolbar>
            </AppBar>
            <Box display="flex" alignItems="center">
                <Divider variant='middle' sx={{ backgroundColor: "#A6A6A6", height: "3px", width: "98%" }} />
            </Box>
        </div>
    );
}

export default Header;
