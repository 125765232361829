import { createContext, useContext, useState } from "react";

// Create a context for the current patient's dashboard
const DashboardContext = createContext();

// Create a hook to use the DashboardContext
export const useDashboardContext = () => useContext(DashboardContext);

// Create a provider for components to consume and subscribe to changes
export const DashboardProvider = ({ children }) => {
  // Define initial states for all your context values
  const initialStates = {
    userName: "",
    userEmail: "",
    patientId: "",
    patientName: "",
    patientEmail: "",
    patientInfo: {},
    latestTrialInfo: {},
    allTrialsInfo: [],
    allTrials: [],
    latestTrial: {},
    latestTrialId: "",
    selectedTrial1: {},
    selectedTrial2: {},
    isLoading: false,
  };

  // Use state hooks for all your variables
  const [state, setState] = useState(initialStates);

  // Reset function to reset all states to their initial values
  const resetDashboard = async () => {
    setState(initialStates);
  };

  // Context provider value should now include the resetDashboard function
  return (
    <DashboardContext.Provider
      value={{
        ...state,
        setUserName: (value) => setState(prev => ({ ...prev, userName: value })),
        setUserEmail: (value) => setState(prev => ({ ...prev, userEmail: value })),
        setPatientId: (value) => setState(prev => ({ ...prev, patientId: value })),
        setPatientName: (value) => setState(prev => ({ ...prev, patientName: value })),
        setPatientEmail: (value) => setState(prev => ({ ...prev, patientEmail: value })),
        setPatientInfo: (value) => setState(prev => ({ ...prev, patientInfo: value })),
        setLatestTrialInfo: (value) => setState(prev => ({ ...prev, latestTrialInfo: value })),
        setAllTrialsInfo: (value) => setState(prev => ({ ...prev, allTrialsInfo: value })),
        setSelectedTrial1: (value) => setState(prev => ({ ...prev, selectedTrial1: value })),
        setSelectedTrial2: (value) => setState(prev => ({ ...prev, selectedTrial2: value })),
        setAllTrials: (value) => setState(prev => ({ ...prev, allTrials: value })),
        setLatestTrial: (value) => setState(prev => ({ ...prev, latestTrial: value })),
        setLatestTrialId: (value) => setState(prev => ({ ...prev, latestTrialId: value })),
        setIsLoading: (value) => setState(prev => ({ ...prev, isLoading: value })),
        resetDashboard
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
