import { Typography, Avatar, Stack } from "@mui/material";
import { useDashboardContext } from "../contexts/DashboardContext";
import { useEffect, useState } from "react";
import Skeleton from '@mui/material/Skeleton';

const UserInfo = () => {
  // const { userName, userEmail } = useDashboardContext();
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    const userName = localStorage.getItem('name');
    const userEmail = localStorage.getItem('email');
    setUserName(userName);
    setUserEmail(userEmail);
  }, []);


  return (
    <Stack direction="row" spacing={1}>
      <Avatar sx={{ bgcolor: "#9FD3D3" }}>
        {userName ? userName.split(' ')[0][0] : <Skeleton variant="circular" width={25} height={25} />}
      </Avatar>
      <Stack direction="column">
        <Typography variant="body2" color="black">
          {userName ? userName : <Skeleton variant="text" width={100} />}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {userEmail ? userEmail : <Skeleton variant="text" width={160} />}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default UserInfo;