import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import { formatTimestamp } from '../../utils/timeUtils';
import LogoImage from '../../assets/images/croppedlogo.png';


const styles = StyleSheet.create({
    headerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 50,
        paddingRight: 50,
        backgroundColor: '#F4F5F7',
        borderRadius: 3,
    },
    headerInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    headerLogoContainer: {
        flexDirection: 'column',
    },
    headerLogo: {
        width: 80,
    },
    headerTitle: {
        fontSize: 25,
        fontWeight: 'bold',
        color: '#000000',
        marginBottom: 8,
    },
    headerInfo: {
        fontSize: 12,
        color: '#000000',
        marginBottom: 5,
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: 20,
    },
    section: {
        flexGrow: 1,
    },
    header: {
        fontSize: 18,
        marginTop: 3,
        marginBottom: 3,
        fontWeight: 'bold',
        color: '#2C3E50'
    },
    info: {
        fontSize: 14,
        marginBottom: 5,
        color: '#34495E',
        marginLeft: 10
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderColor: 'white',
    },
    tableRow: {
        flexDirection: 'row',
        borderStyle: 'solid',
        borderColor: 'white',
        borderWidth: 1,
        backgroundColor: '#F4F5F7',
    },
    tableColHeader: {
        width: '50%',
        backgroundColor: '#BCDDE0',
        padding: 5,
    },
    tableCol: {
        width: '50%',
        padding: 5,
    },
    tableCellHeader: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#000000',
    },
    tableCell: {
        fontSize: 10,
        color: '#000000',
    },
    chartImage: {
        border: '1pt solid #000',
        borderRadius: 5,
        padding: 2,
        width1: '100%',
        height1: 'auto',
    }
});

const captureComponentAsImage = async (componentRef) => {
    const canvas = await html2canvas(componentRef.current);
    return canvas.toDataURL();
}

const patientData = {
    'Patient Name': 'John Doe',
    'Leg Side': 'Left',
    'Trial Date': '2023-10-17',
    'Trial Period': '17:24:33-17:24:58',
    'Session Type': 'Detailed Data Collection',
    'Note': 'None',
};


const PDFDocument = ({ trialInfo, patientInfo, chartImageData }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                {/* Header */}
                <View style={styles.headerContainer}>
                    <View style={styles.headerInfoContainer}>
                        <Text style={styles.headerTitle}>Patient Trial Report</Text>
                        <Text style={styles.headerInfo}>
                            Clinic: Evolution PT{'\n'}
                            Generated Date: {formatTimestamp(new Date())}
                        </Text>
                    </View>
                    <View style={styles.headerLogoContainer}>
                        <Image style={styles.headerLogo} src={LogoImage} />
                    </View>
                </View>

                <Text style={styles.header}>Basic Information</Text>
                {/* Information table */}
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Information</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Content</Text>
                        </View>
                    </View>
                    {Object.entries(patientData).map(([key, value], index) => (
                        <View key={index} style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCellHeader}>{key}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{value}</Text>
                            </View>
                        </View>
                    ))}
                </View>

                <Text style={styles.header}>Summary Metric</Text>
            </View>
        </Page>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                {/* Summary Table */}
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Metric</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Value</Text>
                        </View>
                    </View>
                    {/* Table Rows */}
                    {trialInfo.summaryData && Object.entries(trialInfo.summaryData).map(([key, value], index) => (
                        <View key={index} style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCellHeader}>{key}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{value.toFixed(2)}</Text>
                            </View>
                        </View>
                    ))}
                </View>
            </View>
            {/* <View style={styles.section}>
                <Text style={styles.header}>Raw Data:</Text>
                <Image src={chartImageData} style={styles.chartImage} />
            </View> */}
        </Page>
    </Document>
);


export default PDFDocument;