import Box from "@mui/material/Box";
import ReactEcharts from "echarts-for-react";
import { forwardRef } from "react";
import NoDataAvailable from "../../components/NoDataAvailable";

const AnkleSpeedChart = forwardRef((props, ref) => {
    const { rawData } = props;

    if (!rawData || rawData.length === 0) {
        return (
            <NoDataAvailable />
        );
    }

    const timeSeries = rawData.map(item => item.ms / 1000);
    const AnkleSpeed = rawData.map(item => item.ankle_speed_vx);

    const minTime = Math.min(...timeSeries);
    const normalizedTimeSeries = timeSeries.map(time => time - minTime)
    const interval = Math.floor(normalizedTimeSeries.length / 10);

    const option = {
        title: {
            text: 'Ankle Speed',
            left: 'left',
            padding: 10,
        },
        tooltip: {
            trigger: 'axis',
        },
        legend: {},
        xAxis: {
            type: 'category',
            axisLabel: {
                interval: interval,
                formatter: function (value) {
                    return parseFloat(value).toFixed(2);
                }
            },
            data: normalizedTimeSeries || [],
            name: 'Time',
            nameTextStyle: {
                fontSize: 15,
            },
            axisLine: {
                lineStyle: {
                    color: '#000000',
                },
            },
        },
        yAxis: {
            type: 'value',
            name: '',
            nameTextStyle: {
                fontSize: 15,
            },
            axisLine: {
                lineStyle: {
                    color: '#000000',
                },
            },
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {
                    show: true,
                    title: 'Save As Image',
                    name: 'ankle_speed_vx',
                    excludeComponents: ['toolbox'],
                    pixelRatio: 2,
                },
            },
        },
        dataZoom: [
            {
                type: 'slider',
                start: 0,
                end: 100,
                bottom: 10,
                xAxisIndex: [0],
                borderColor: '#68B3C8', // Same as the series line color for consistency
                handleColor: '#68B3C8', // Same as the series line color for consistency
                handleSize: '80%',
                textStyle: {
                    color: '#333' // Keep text legible and neutral
                }
            },
            {
                type: 'inside',
                start: 0,
                end: 100,
                xAxisIndex: [0],
            },
        ],
        series: [
            {
                name: 'Ankle Speed',
                data: AnkleSpeed || [],
                type: 'line',
                smooth: true,
                lineStyle: {
                    normal: {
                        width: 4,
                        type: 'solid',
                        color: '#9FD3D3',
                    },
                },
                areaStyle: {
                    color: '#E9EFFD',
                },
                symbolSize: 6,
                itemStyle: {
                    color: '#9FD3D3',
                },
            },
            // {
            //     name: 'Average',
            //     data: averageLineData,
            //     type: 'line',
            //     lineStyle: {
            //         normal: {
            //             width: 2,
            //             type: 'dashed',
            //             color: '#D0312D',
            //         },
            //     },
            //     showSymbol: false,
            //     symbolSize: 6,
            //     itemStyle: {
            //         color: '#D0312D',
            //     },
            // }
        ],
    };

    return (
        <Box width="80%" height="400px" borderRadius={3} boxShadow={5} p={1}>
            <ReactEcharts ref={ref} option={option} style={{ height: '100%' }} />
        </Box>
    );
});

export default AnkleSpeedChart;
