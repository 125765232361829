import {
  Stack,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDashboardContext } from "../../contexts/DashboardContext";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const timeZone = 'America/New_York';

const TrialPicker = ({
  selectedDate,
  setSelectedDate,
  setCurrentTrialId,
  currentTrialId
}) => {
  const { allTrials } = useDashboardContext();
  const [selectedTrialIndex, setSelectedTrialIndex] = useState(null);

  // const availableTrialsOnSelectedDate = allTrials.filter((trial) => {
  //   return startOfDay(new Date(trial.date)).getTime() === startOfDay(new Date(selectedDate)).getTime();
  // });

  // const shouldDisableDate = (date) => {
  //   const localDate = startOfDay(date);
  //   localDate.setHours(12);

  //   return !allTrials.some((trial) => {
  //     const trialDate = startOfDay(new Date(trial.date));
  //     return isSameDay(trialDate, localDate);
  //   });
  // };

  // const handleTrialSelect = (event) => {
  //   const selectedIndex = event.target.value;
  //   setSelectedTrialIndex(selectedIndex);
  //   if (selectedIndex >= 0) {
  //     const selectedTrial = availableTrialsOnSelectedDate[selectedIndex];
  //     setCurrentTrialId(selectedTrial.id);
  //   }
  // };

  // useEffect(() => {
  //   if (currentTrialId && availableTrialsOnSelectedDate.length > 0) {
  //     const savedTrialIndex = availableTrialsOnSelectedDate.findIndex(trial => trial.id === currentTrialId);
  //     setSelectedTrialIndex(savedTrialIndex >= 0 ? savedTrialIndex : 0);
  //   }
  // }, [currentTrialId, availableTrialsOnSelectedDate]);

  const selectedDay = dayjs(selectedDate).tz(timeZone).startOf('day');

  const availableTrialsOnSelectedDate = allTrials.filter((trial) => {
    const trialDay = dayjs(trial.date).tz(timeZone).startOf('day');
    return selectedDay.isSame(trialDay);
  });

  const shouldDisableDate = (date) => {
    const localDate = dayjs(date).tz(timeZone).startOf('day');
    return !allTrials.some((trial) => {
      const trialDate = dayjs(trial.date).tz(timeZone).startOf('day');
      return trialDate.isSame(localDate);
    });
  };


  // Handle the selection change in the Select component
  const handleTrialSelect = (event) => {
    const selectedIndex = event.target.value;
    // setSelectedTrialIndex(selectedIndex);
    if (selectedIndex >= 0) {
      const selectedTrial = availableTrialsOnSelectedDate[selectedIndex];
      setCurrentTrialId(selectedTrial.id);
    }
  };

  // useEffect(() => {
  //   if (selectedDate && availableTrialsOnSelectedDate.length > 0 && selectedTrialIndex === null) {
  //     setSelectedTrialIndex(0);
  //     setCurrentTrialId(availableTrialsOnSelectedDate[0].id);
  //   }
  // }, [selectedDate, availableTrialsOnSelectedDate, selectedTrialIndex, setCurrentTrialId]);


  // useEffect(() => {
  //   const savedTrialId = currentTrialId;
  //   if (savedTrialId) {
  //     const savedTrialIndex = availableTrialsOnSelectedDate.findIndex(trial => trial.id === savedTrialId);
  //     if (savedTrialIndex >= 0) {
  //       setSelectedTrialIndex(savedTrialIndex);
  //     } else if (availableTrialsOnSelectedDate.length > 0 && selectedTrialIndex === null && currentTrialId === null) {
  //       setSelectedTrialIndex(0);
  //       setCurrentTrialId(availableTrialsOnSelectedDate[0].id);
  //     } else {
  //       setSelectedTrialIndex(null);
  //       setCurrentTrialId(null);
  //     }
  //   }
  // }, [currentTrialId, availableTrialsOnSelectedDate]);

  useEffect(() => {
    const savedTrialId = currentTrialId;
    if (savedTrialId) {
      const savedTrialIndex = availableTrialsOnSelectedDate.findIndex(trial => String(trial.id) === String(savedTrialId));
      if (savedTrialIndex >= 0) {
        setSelectedTrialIndex(savedTrialIndex);
      }
    } else if (availableTrialsOnSelectedDate.length > 0) {
      setSelectedTrialIndex(0);
    }
  }, [availableTrialsOnSelectedDate]);


  return (
    <Box>
      <Stack direction="column" spacing={1}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {/* Date Picker */}
          <DatePicker
            label="Select Date"
            value={selectedDate}
            onChange={(newValue) => {
              setSelectedDate(newValue);
            }}
            shouldDisableDate={shouldDisableDate}
            sx={{ maxWidth: 200 }}
          />
        </LocalizationProvider>
        {/* Trial Picker */}
        <Box sx={{ maxWidth: 200 }}>
          <FormControl fullWidth variant="outlined">
            {/* <InputLabel id="select-trial-label">Select Trial</InputLabel> */}
            <Select
              id="select-trial"
              labelId="select-trial-label"
              onChange={handleTrialSelect}
              value={selectedTrialIndex}
            >
              {availableTrialsOnSelectedDate.map((trial, index) => (
                <MenuItem divider={true} key={index} value={index}>
                  {trial.startTimestamp}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>
    </Box>
  );
};

export default TrialPicker;
