import {
  Box,
  Drawer,
  List,
  Stack,
  Toolbar,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { auth, db } from "../firebase";
import sizeConfigs from "../configs/sizeConfigs";
import HomeIcon from "@mui/icons-material/Home";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import assets from "../assets";
import colorConfigs from "../configs/colorConfigs";
import UserInfo from "./UserInfo";
import CustomizedButton from "./CustomizedButton";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Divier from "@mui/material/Divider";
import { useDashboardContext } from "../contexts/DashboardContext";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MailIcon from "@mui/icons-material/Mail";
import FeedbackIcon from "@mui/icons-material/Feedback";
import Skeleton from '@mui/material/Skeleton';
import { useLocation } from "react-router-dom";
import { set } from "date-fns";

const Sidebar = () => {
  const location = useLocation();
  const { patientId, patientInfo, latestTrialId, resetDashboard } = useDashboardContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    try {
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('clinicianId');
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('name');
      localStorage.removeItem('email');
      resetDashboard();
      navigate('/');
      console.log("Logged out successfully!");
    } catch (error) {
      console.error('There was an error logging out:', error);
    }
  };

  const switchPatient = () => {
    navigate("/patients");
    resetDashboard();
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        display: "flex",
        flexDirection: "column",
        //width: isSidebarCollapsed ? sizeConfigs.sidebar.collapsedWidth - 100: sizeConfigs.sidebar.width,
        width: sizeConfigs.sidebar.width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: sizeConfigs.sidebar.width,
          //width: isSidebarCollapsed ? sizeConfigs.sidebar.collapsedWidth - 100 : sizeConfigs.sidebar.width,
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: colorConfigs.sidebar.background,
        },
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <List disablePadding>
          {/* Logo */}
          <Toolbar sx={{ marginBottom: "10px", marginTop: "10px" }}>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="center"
            >
              <img src={assets.images.logo} width={200} alt="Logo" />
            </Stack>
          </Toolbar>
          {/* Patient Info */}
          <Toolbar sx={{ marginBottom: "10px" }}>
            <Stack
              sx={{ width: "100%" }}
              direction="column"
              justifyContent="center"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography component="div" variant="h6">
                  {patientInfo.name ? patientInfo.name : <Skeleton variant="text" width={140} />}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" mb={0.5}>
                {patientInfo.email ? (
                  <MailIcon sx={{ marginRight: 0.5, color: "#707070" }} />
                ) : (
                  <Skeleton variant="circular" width={25} height={25} sx={{ marginRight: 0.5 }} />
                )
                }
                <Typography variant="body1">
                  {patientInfo.email ? patientInfo.email : <Skeleton variant="text" width={160} />}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <CustomizedButton onClick={() => switchPatient()}>
                  <SwapVertIcon />
                  Switch Patient
                </CustomizedButton>
              </Box>
            </Stack>
          </Toolbar>
          <Divier variant="middle" />
          <ListItem>
            <ListItemButton component={Link} to={`/dashboard/${patientId}`}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Home"
                primaryTypographyProps={{
                  fontWeight: location.pathname === `/dashboard/${patientId}` ? 'bold' : 'normal'
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={Link}
              to={`/dashboard/${patientId}/analyze/${latestTrialId}`}
            >
              <ListItemIcon>
                <AnalyticsIcon />
              </ListItemIcon>
              <ListItemText
                primary="Analytics"
                primaryTypographyProps={{
                  fontWeight: location.pathname.startsWith(`/dashboard/${patientId}/analyze`) ? 'bold' : 'normal'
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={Link}
              to={`/dashboard/${patientId}/compare/${latestTrialId}/vs/${latestTrialId}`}
            >
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText
                primary="Comparison"
                primaryTypographyProps={{
                  fontWeight: location.pathname.startsWith(`/dashboard/${patientId}/compare`) ? 'bold' : 'normal'
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={Link}
              to={`/dashboard/${patientId}/daily`}
            >
              <ListItemIcon>
                <SignalCellularAltIcon />
              </ListItemIcon>
              <ListItemText
                primary="Daily"
                primaryTypographyProps={{
                  fontWeight: location.pathname.startsWith(`/dashboard/${patientId}/daily`) ? 'bold' : 'normal'
                }}
              />
            </ListItemButton>
          </ListItem>
          {/* <ListItem>
            <ListItemButton
              component={Link}
              to={`/dashboard/${patientId}/reports/${latestTrialId}`}
            >
              <ListItemIcon>
                <SummarizeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Reports"
                primaryTypographyProps={{
                  fontWeight: location.pathname.startsWith(`/dashboard/${patientId}/reports`) ? 'bold' : 'normal'
                }}
              />
            </ListItemButton>
          </ListItem> */}
          <ListItem>
            <ListItemButton
              component={Link}
              to={`/dashboard/${patientId}/feedback`}
            >
              <ListItemIcon>
                <FeedbackIcon />
              </ListItemIcon>
              <ListItemText
                primary="Feedback"
                primaryTypographyProps={{
                  fontWeight: location.pathname === `/dashboard/${patientId}/feedback` ? 'bold' : 'normal'
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Box display="flex" flexDirection="column" pb={2} pl={2} width="100%">
        <Box display="flex" justifyContent="flex-start" mb={1}>
          <CustomizedButton variant="text" onClick={handleLogout}>
            <PowerSettingsNewIcon fontSize="small" />
            Logout
          </CustomizedButton>
        </Box>
        <UserInfo />
      </Box>
    </Drawer>
  );
};

export default Sidebar;
