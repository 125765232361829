import Button from "@mui/material/Button";

function CustomizedButton(props) {
  return (
    <Button
      sx={{
        color: "#91CBD0",
        transition: "0.3s",
        textTransform: "none",
        '&:hover': {
          backgroundColor: 'rgba(13, 180, 249, 0.1)',
      }, 
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
}

export default CustomizedButton;
