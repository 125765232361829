import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDashboardContext } from "../../contexts/DashboardContext";
import LinearProgress from '@mui/material/LinearProgress';
import DataGridTable from "./DataGridTable";
import { styled } from "@mui/material/styles";
import OneTapButton from "./OneTapButton";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { getAllTrialsByPatientId } from "../../utils/fastAPI";

const PatientHomePage = () => {
  const { patientId } = useDashboardContext();
  const { allTrials, latestTrialId, isLoading } = useDashboardContext();
  const { setAllTrials, setLatestTrial, setLatestTrialId, setIsLoading } = useDashboardContext();

  const handleRefresh = async () => {
    if (patientId) {
      setIsLoading(true);
      const trials = await getAllTrialsByPatientId(patientId);
      if (trials) {
        const latestTrial = trials[0];
        const latestTrialId = trials[0]?.id;
        setAllTrials(trials);
        setLatestTrial(latestTrial);
        setLatestTrialId(latestTrialId);
        setIsLoading(false);
      }
    }
  }

  const StyledTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    color: '#5a5a5a',
    display: 'inline-block',
    marginRight: theme.spacing(2),
  }));

  const navigate = useNavigate();

  const handleAnalyzeClick = () => {
    navigate(`/dashboard/${patientId}/analyze/${latestTrialId}`);
  }

  const handleCompareClick = () => {
    navigate(`/dashboard/${patientId}/compare/${latestTrialId}/vs/${latestTrialId}`);
  }

  return (
    <Box display="flex" flexDirection="column" m={0.5}>
      {/* Subtitle */}
      {/* <StyledTypography variant="h6">
        One Tap Functions
      </StyledTypography> */}
      {/* One Tap Button */}
      <Box mb={1}>
        <Box display="flex">
          <OneTapButton title="Display latest clinical trial" subtitle="Detail data and trend chart" onClick={handleAnalyzeClick} />
          <OneTapButton title="Compare two trials" subtitle="Detail data and trend chart" onClick={handleCompareClick} />
          {/* <OneTapButton title="Generate report" subtitle="Detail and trend chart" /> */}
        </Box>
      </Box>
      <Box>
        <Box mb={1}>
          <StyledTypography variant="h6" >
            All Trials
          </StyledTypography>
        </Box>
        {isLoading ? (
          <Stack direction="row" justifyContent="center" mt={15}>
            <Box width="100%">
              <LinearProgress />
            </Box>
          </Stack>
        ) : <DataGridTable data={allTrials} handleRefresh={handleRefresh} />
        }
      </Box>
    </Box >
  );
};

export default PatientHomePage;
