import * as React from 'react';
import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    tableCellClasses,
    Paper,
    TablePagination,
  } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomizedButton from "./CustomizedButton";
import { styled } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // root: {
    //   height: 20
    // },
    "&:nth-of-type(even)": {
      backgroundColor: "white",
      borderBottom: "none",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
      borderBottom: "none",
    },
    // hide last border
    // "&:last-child td, &:last-child th": {
    //   border: 0,
    // },
  }));
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(137, 206, 211, 0.5)",
      color: theme.palette.common.black,
      fontSize: 16,
      width: "auto",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 'normal',
    },
  }));
  
  function CustomizedTable ({ headers, data, includeButton = false, includePagination = false, butttonText = "Generate Report", dateColumnIndex}) {
    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [sortOrder, setSortOrder] = React.useState('asc');
    const [sortedData, setSortedData] = React.useState([]);

    React.useEffect(() => {
      setSortedData([...data]);
    }, [data]);    

    // Ascending Sort
    const handleSortAsc = () => {
      const sorted = [...data].sort((a, b) => {
        if (dateColumnIndex !== undefined) {
          return new Date(a[dateColumnIndex]) - new Date(b[dateColumnIndex]);
        } else {
          return new Date(a.date) - new Date(b.date);
        }
      });
      setSortedData(sorted);
      setSortOrder('asc');
    };

    // Descending Sort
    const handleSortDesc = () => {
      const sorted = [...data].sort((a, b) => {
        if (dateColumnIndex !== undefined) {
          return new Date(b[dateColumnIndex]) - new Date(a[dateColumnIndex]);
        } else {
          return new Date(b.date) - new Date(a.date);
        }
      });
      setSortedData(sorted);
      setSortOrder('desc');
    };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const handleFetchPatientId = (patientId) => {
      navigate(`/dashboard/${patientId}`);
    }

    return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <StyledTableCell key={header}>
                {header}
                {header === "Date" && (
                  <React.Fragment>
                    <IconButton onClick={sortOrder === 'asc' ? handleSortDesc : handleSortAsc} size="small">
                      {sortOrder === 'asc' ? <ArrowUpward fontSize="inherit" /> : <ArrowDownward fontSize="inherit" />}
                    </IconButton>
                  </React.Fragment>
                )}
              </StyledTableCell>
            ))}
            {includeButton && <StyledTableCell></StyledTableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => (
            <StyledTableRow hover key={index} >
              {Object.values(row).map((cell, i) => (
                <StyledTableCell key={i}>{cell}</StyledTableCell>
              ))}
              {includeButton && (
              <StyledTableCell sx={{ width: "160px"}}>
                <CustomizedButton 
                  variant="text"
                  onClick={() => handleFetchPatientId(row.id)}
                >
                  {butttonText}
                </CustomizedButton>
              </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      { includePagination && 
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> 
      }
    </TableContainer>
    );
  }
  
  export default CustomizedTable;