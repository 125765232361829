import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    tableCellClasses,
    Paper,
    TablePagination,
    Box,
    Alert,
    AlertTitle,
    Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { checkIfPatientHasTrials } from "../../utils/dataFetcher";

// Styled table row component
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#FFFFFF",
        borderBottom: "none",
    },
    "&:nth-of-type(odd)": {
        backgroundColor: "#FFFFFF",
        borderBottom: "none",
    },
}));

// Styled table cell component
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#9FD3D3",
        color: theme.palette.common.black,
        fontSize: 18,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const PatientsTable = ({ data }) => {
    const [showAlert, setShowAlert] = useState(false);

    const navigate = useNavigate();

    const handleFetchPatientId = async (patientId) => {
        try {
            // const hasTrials = await checkIfPatientHasTrials(patientId);
            // if (hasTrials) {
            //     navigate(`/dashboard/${patientId}`);
            // } else {
            //     setShowAlert(true);
            // }
            navigate(`/dashboard/${patientId}`);
        } catch (error) {
            console.error("Error fetching trial data: ", error);
        }
    }

    // Handle pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Box>
            {showAlert && (
                <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={3000} open={showAlert} onClose={() => setShowAlert(false)}>
                    <Alert severity="warning" onClose={() => setShowAlert(false)} sx={{ width: '500px' }}>
                        <AlertTitle>No Trials</AlertTitle>
                        This patient has no trials
                    </Alert>
                </Snackbar>
            )}
            <TableContainer component={Paper}>
                <Table size="small">
                    {/* Table Head */}
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Patient Name</StyledTableCell>
                            <StyledTableCell>Email</StyledTableCell>
                            <StyledTableCell>Condition</StyledTableCell>
                            <StyledTableCell>Last Date Used</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {/* Table Body */}
                    <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <StyledTableRow hover key={index} sx={{ cursor: 'pointer' }} onClick={() => handleFetchPatientId(row.id)}>
                                    <StyledTableCell>{row.name}</StyledTableCell>
                                    <StyledTableCell>{row.email}</StyledTableCell>
                                    <StyledTableCell>{row.medicalCondition}</StyledTableCell>
                                    <StyledTableCell>{row.lastDateUsed}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
                {/* Pagination component */}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Box>
    );
}

export default PatientsTable;
