const colorConfigs = {
    primary: "#9FD4D3",
    secondary: "#45C0FA",
    tertiary: "#F5F5F5",
    sidebar: {
        background: "#F5F5F5",
    },
    multiselector: {
        background: "#F4F5F7",
        chip_background: "#9FD3D3",
        chip_text: "#FFFFFF",
    },
    togglebutton: {
        text: "black",
        background: "#FFFFFF",
        selected_text: "#67BCC3",
        selected_background: "#FFFFFF",
        hover_text: "#67BCC3",
        hover_background: "rgba(69, 192, 250, 0.1)",
    },
};

export default colorConfigs;