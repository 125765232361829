import React from 'react';
import Alert from '@mui/material/Alert';

const NoDataAvailable = () => {
    return (
        <Alert severity="info">
            No graph available
        </Alert>
    );
};

export default NoDataAvailable;