import Box from "@mui/material/Box";
import MultiSelector from "../../components/MultiSelector";
import KneeFlexChart from "./KneeFlexChart";
import HipFlexChart from "./HipFlexChart";
import AnkleSpeedChart from "./AnkleSpeedChart";
import IsWalkChart from "./IsWalkChart";
import FootDragChart from "./FootDragChart";
import GaitCycleChart from "./GaitCycleChart";
import Stack from "@mui/material/Stack";
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDashboardContext } from "../../contexts/DashboardContext";
import { getRawDataByTrialId } from '../../utils/fastAPI'

const Graphs = ({ currentTrialId, selectedMetrics, setSelectedMetrics, setPdfCharts }) => {
  const [rawData, setRawData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { latestTrialId } = useDashboardContext();
  const { trialId } = useParams();

  const kneeFlexRef = useRef();
  const hipFlexRef = useRef();
  const ankleSpeedRef = useRef();
  const isWalkRef = useRef();
  const footDragRef = useRef();
  const gaitCycleRef = useRef();

  const captureChart = (chartRef) => {
    if (!latestTrialId) {
      return;
    }

    if (chartRef.current) {
      const echartsInstance = chartRef.current.getEchartsInstance();
      // echartsInstance.setOption({
      //   xAxis: { name: false },
      //   yAxis: { name: false },
      // });

      // Capture the chart
      const url = echartsInstance.getDataURL({
        pixelRatio: 2,
        backgroundColor: '#fff',
        excludeComponents: ['toolbox', 'dataZoom', 'title', 'legend', 'tooltip',],
      });

      // echartsInstance.setOption({
      //   xAxis: { name: true },
      //   yAxis: { name: true },
      // });

      return url;
    }
  };

  const captureCharts = async () => {
    if (!latestTrialId) {
      return;
    }

    const images = [];

    // List of chart refs and their titles in the desired order
    const chartRefs = [
      { ref: gaitCycleRef, title: "Gait Cycle Chart" },
      { ref: isWalkRef, title: "Is Walk Chart" },
      { ref: kneeFlexRef, title: "Knee Flexion Chart" },
      { ref: hipFlexRef, title: "Hip Flexion Chart" },
      { ref: footDragRef, title: "Foot Drag Chart" },
      { ref: ankleSpeedRef, title: "Ankle Speed Chart" },
    ];

    // Capture charts in the predefined order
    for (let chart of chartRefs) {
      if (chart.ref.current) {
        const chartImage = captureChart(chart.ref);
        if (chartImage) {
          images.push({ src: chartImage, title: chart.title });
        }
      }
    }

    setPdfCharts(images);
  };

  // const fetchRawData = async () => {
  //   setIsLoading(true);
  //   const data = await getRawDataByTrialId(trialId);
  //   setRawData(data);
  //   setIsLoading(false);
  // }

  useEffect(() => {
    if (!latestTrialId) {
      return;
    }

    const fetchRawData = async () => {
      setIsLoading(true);
      try {
        const data = await getRawDataByTrialId(currentTrialId);
        setRawData(data);
      } catch (error) {
        console.error("Error fetching raw data:", error);
        // Handle error
      } finally {
        setIsLoading(false);
      }
    };

    fetchRawData();
  }, [currentTrialId]);

  useEffect(() => {
    if (!currentTrialId) {
      return;
    }

    setTimeout(() => {
      captureCharts();
    }, 3000);
  }, [currentTrialId, selectedMetrics]);


  return (
    <Box mt={0} mb={1}>
      {/* MultiSelector */}
      <Box bgcolor="#F4F5F7" borderRadius={1} mb={3} height="70px">
        <MultiSelector
          selectedMetrics={selectedMetrics}
          onChange={setSelectedMetrics}
        />
      </Box>
      {isLoading && (
        <Box width="100%" mt={10}>
          <LinearProgress />
        </Box>
      )}
      {!isLoading && (
        <Stack spacing={3} alignItems="center">
          {selectedMetrics.includes("Gait Cycle") && (
            <GaitCycleChart rawData={rawData} ref={gaitCycleRef} />
          )}
          {selectedMetrics.includes("Knee Flexion") && (
            <KneeFlexChart rawData={rawData} ref={kneeFlexRef} />
          )}
          {selectedMetrics.includes("Hip Flexion") && (
            <HipFlexChart rawData={rawData} ref={hipFlexRef} />
          )}
          {selectedMetrics.includes("Walking Probability") && (
            <IsWalkChart rawData={rawData} ref={isWalkRef} />
          )}
          {selectedMetrics.includes("Foot Drag Probability") && (
            <FootDragChart rawData={rawData} ref={footDragRef} />
          )}
          {selectedMetrics.includes("Ankle Speed") && (
            <AnkleSpeedChart rawData={rawData} ref={ankleSpeedRef} />
          )}
        </Stack>
      )}
    </Box>
  );
};

export default Graphs;
