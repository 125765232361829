import moment from "moment";
import 'moment-timezone';

export const formatTimestamp = (timestamp) => {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
    const date = moment.unix(timestamp / 1000);
    return date.tz(currentTimeZone).format("MMMM Do YYYY, h:mm:ss a");
  };
  
  export const getDayOfWeek = (timestamp) => {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
    const date = moment.unix(timestamp / 1000);
    return date.tz(currentTimeZone).format("dddd");
  };

 export const shortenDate = (dateString) => {
    const dateParts = dateString.split('-');
    const shortenedYear = dateParts[0].substring(2);
    const shortenedDate = `${shortenedYear}-${dateParts[1]}-${dateParts[2]}`;
    return shortenedDate;
  };

  export const formatTrialDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    return date;
  }

  export const getLocalDate = (date) => {
    const localDate = new Date(date);
    localDate.setHours(12);
    return localDate;
  }

  export const formatDateString = (date, view) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"];
    const monthsAbbreviate = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    if (view === "week") {
        return date.toISOString().split('T')[0]; 
    } else if (view === "month") {
      // const year = date.getFullYear();
      // const month = date.getMonth();
      // const firstDay = new Date(year, month, 1);
      // const lastDay = new Date(year, month + 1, 0);
      // return `${monthsAbbreviate[month]} ${firstDay.getDate()} - ${monthsAbbreviate[month]} ${lastDay.getDate()} ${year}`;
        // like "January 2024"
      return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
    } else if (view === "date") {
      const month = date.getMonth(); 

      return `${monthsAbbreviate[month]} ${date.getDate()}`;
    }
    else {
        // like "2024"
        return `${date.getFullYear()}`;
    }
};

export const getWeekStartEnd = (date) => {
  const firstDayOfWeek = date.getDate() - date.getDay(); // First day is the day of the month - the day of the week
  const lastDayOfWeek = firstDayOfWeek + 6; // last day is the first day + 6

  const firstDay = new Date(date.setDate(firstDayOfWeek));
  const lastDay = new Date(date.setDate(lastDayOfWeek));

  return { firstDay, lastDay };
}
