import {
    Stack,
    Box,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDashboardContext } from "../../contexts/DashboardContext";
import dayjs from "dayjs";
import { formatTimestamp } from "../../utils/timeUtils";
import { useEffect, useState } from "react";

const TrialPicker = ({
    selectedDate,
    setSelectedDate,
    setCurrentTrialId,
    currentTrialId
}) => {
    const { allTrials } = useDashboardContext();
    const [selectedTrialIndex, setSelectedTrialIndex] = useState(null);

    const availableTrialsOnSelectedDate = allTrials.filter((trial) => {
        const trialDate = dayjs(trial.date);
        return trialDate.isSame(selectedDate, "day");
    });

    const shouldDisableDate = (date) => {
        return !allTrials.some((trial) => {
            const trialDate = dayjs(trial.date);
            return trialDate.isSame(date, "day");
        });
    };

    // Handle the selection change in the Select component
    const handleTrialSelect = (event) => {
        const selectedIndex = event.target.value; // Get the selected index
        if (selectedIndex >= 0) {
            // Ensure a valid index is selected
            const selectedTrial = availableTrialsOnSelectedDate[selectedIndex]; // Get the selected trial
            setCurrentTrialId(selectedTrial.id); // Update the selectedTrialInfo state
        }
    };

    useEffect(() => {
        const savedTrialId = currentTrialId;
        if (savedTrialId) {
            const savedTrialIndex = availableTrialsOnSelectedDate.findIndex(trial => trial.id === savedTrialId);
            if (savedTrialIndex >= 0) {
                setSelectedTrialIndex(savedTrialIndex);  // Set the selected trial index for the dropdown
            }
        } else if (availableTrialsOnSelectedDate.length > 0) {
            setSelectedTrialIndex(0);
        }
    }, [availableTrialsOnSelectedDate]);

    return (
        <Box>
            <Stack direction="row" spacing={5}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {/* Date Picker */}
                    <DatePicker
                        label="Select Date"
                        value={selectedDate}
                        onChange={(newValue) => {
                            setSelectedDate(newValue);
                        }}
                        shouldDisableDate={shouldDisableDate}
                        sx={{ maxWidth: 200 }}
                    />
                </LocalizationProvider>
                {/* Trial Picker */}
                <Box sx={{ minWidth: 200 }}>
                    <FormControl fullWidth>
                        <InputLabel id="select-trial-label">Select Trial</InputLabel>
                        <Select
                            labelId="select-trial-label"
                            id="select-trial"
                            label="Select Trial"
                            onChange={handleTrialSelect}
                        >
                            {availableTrialsOnSelectedDate.map((trial, index) => (
                                <MenuItem key={index} value={index}>
                                    {formatTimestamp(trial.startTimestamp)}-
                                    {formatTimestamp(trial.endTimestamp)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Stack>
        </Box>
    );
};

export default TrialPicker;
