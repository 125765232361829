import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { formatTimestamp } from '../../utils/timeUtils';
import LogoImage from '../../assets/images/croppedlogo.png';


const styles = StyleSheet.create({
    headerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 50,
        paddingRight: 50,
        backgroundColor: '#F4F5F7',
        borderRadius: 3,
    },
    headerInfoContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    headerLogoContainer: {
        flexDirection: 'column',
    },
    headerLogo: {
        width: 60,
    },
    headerTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#000000',
        marginBottom: 2,
    },
    headerInfo: {
        fontSize: 12,
        color: '#000000',
        marginBottom: 10,
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: 20,
    },
    section: {
        flexGrow: 1,
    },
    header: {
        fontSize: 18,
        marginTop: 3,
        marginBottom: 3,
        fontWeight: 'bold',
        color: '#2C3E50'
    },
    info: {
        fontSize: 14,
        marginBottom: 5,
        color: '#34495E',
        marginLeft: 10
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderColor: 'white',
    },
    tableRow: {
        flexDirection: 'row',
        borderStyle: 'solid',
        borderColor: 'white',
        borderWidth: 1,
        backgroundColor: '#F4F5F7',
    },
    tableColHeader: {
        width: '50%',
        backgroundColor: '#BCDDE0',
        padding: 5,
    },
    tableCol: {
        width: '50%',
        padding: 5,
    },
    tableCellHeader: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#000000',
    },
    tableCell: {
        fontSize: 10,
        color: '#000000',
    },
    imageContainer: {
        marginBottom: 1,
        marginTop: 5,
        borderRadius: 3,
    },
    chartImage: {
        width: 'auto',
        height: '50%'
    },
    imageTitle: {
        fontSize: 14,
        fontWeight: 'bold',
    },
});

const PDFDocument = ({ trialInfo1, trialInfo2, tableContent, patientInfo, pdfCharts, summaryData1, summaryData2 }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                {/* Header */}
                <View style={styles.headerContainer}>
                    <View style={styles.headerInfoContainer}>
                        <Text style={styles.headerTitle}>Patient Trial Report</Text>
                        <Text style={styles.headerInfo}>
                            Clinic: Evolution PT{'\n'}
                            Generated Date: {formatTimestamp(new Date())}
                        </Text>
                    </View>
                    <View style={styles.headerLogoContainer}>
                        <Image style={styles.headerLogo} src={LogoImage} />
                    </View>
                </View>

                <Text style={styles.header}>Basic Information</Text>
                {/* Information table */}
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Information</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Content</Text>
                        </View>
                    </View>
                    {/* Table Rows */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Patient Name</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{patientInfo.name}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Patient Email</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{patientInfo.email}</Text>
                        </View>
                    </View>
                </View>
                <Text style={styles.header}>Trials Information</Text>
                {/* Information table */}
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Information</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Trial1</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Trial2</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Leg Side</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{summaryData1.deviceLocation}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{summaryData2.deviceLocation}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Trial Date</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{trialInfo1.date}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{trialInfo2.date}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Trial Period</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{trialInfo1.timePeriod}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{trialInfo2.timePeriod}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Session Type</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{trialInfo1.sessionType}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{trialInfo2.sessionType}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCellHeader}>Note</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{trialInfo1.notes}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>{trialInfo2.notes}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={styles.section}>
                <Text style={styles.header}>Summary Metric</Text>
                {/* Summary table */}
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Metric</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Trial1 Value</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Trial2 Value</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Difference</Text>
                        </View>
                    </View>
                    {/* Table Rows */}
                    {tableContent.map(item => (
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCellHeader}>{item.metric}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {item.trial1.toFixed(2)}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {item.trial2.toFixed(2)}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {item.difference.toFixed(2)}
                                </Text>
                            </View>
                        </View>
                    ))}
                </View>
            </View>
        </Page>
        {pdfCharts.map((item, index) => (
            <Page key={index} size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.imageContainer}>
                        <Text style={styles.imageTitle}>{item.title}</Text>
                        <Image style={styles.chartImage} src={item.src1} />
                        <Image style={styles.chartImage} src={item.src2} />
                    </View>
                </View>
            </Page>
        ))}
    </Document >
);


export default PDFDocument;